import React, { useState, useEffect, Fragment } from "react";
import { useHistory, Redirect } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle, obtenerValorConfig, replaceValue } from '../services/configStyle';

import {status, statusData} from '../services/data'

const Terminos = () => {
    const history = useHistory();
    const [dataUser, setDataUser] = React.useState([])
    const [dataOtorgante, setDataOtorgante] = useState([])


    useEffect(() => {
        let dataUserLS = localStorage.getItem("data_user");
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS));
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante");
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS));
        }
        status("terminos_y_condiciones_page", "Terminos y condiciones")
    }, [])
    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser));
    }, [dataUser]);


    useEffect(() => {
        configStyle(dataOtorgante);
    }, [dataOtorgante]);

    const aceptarTerminos = check => {
        setDataUser([{ description: "terms_acepted", value: check }]);
        let data = { terminos_aceptados: true}
        if (check) {
            statusData("terminos_y_condiciones", data)
            setTimeout(() => {
                history.push("/preparacion_identificacion");
            }, 1000);
        }
    };

    const printText = () => {
        let textToPrint = "";
        let textStorage = obtenerValorConfig(dataOtorgante, "TEXTO_DE_CONSENTIMIENTO_1");
        textToPrint = replaceValue(textStorage, "{OTORGANTE}", obtenerValorConfig(dataOtorgante, "RAZON_SOCIAL"));
        if (textToPrint) {
            return textToPrint.split("{enter}").map(line => {
                return (<Fragment key="1">{line} <br /></Fragment>);
            });
        } else {
            return textToPrint;
        }
    };

    return (
        <div className="main_gradient">
            {(obtenerValorConfig(dataOtorgante, "REQ_ACEPTACION_DE_DATOS", "VERDADERO") === "FALSO") ? <Redirect to="/preparacion_identificacion" /> : ""}
            <Header ruta="terminos" />
            <h5 className="left-align margin_4 text-color">
                <b>{(obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_1", "FALSO") === "FALSO") ? ("") : obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_1")}</b>
            </h5>
            <div className="terms_container">
                <div className="terms_bg_box">
                    <p align="left" className="left-align">
                        <h6 className="text-right text-small text-strong">
                            <small>
                                <strong>
                                    {(obtenerValorConfig(dataOtorgante, "LUGAR_Y_FECHA_CONSENTIMIENTO_1", "FALSO") === "FALSO") ? ("") : (<Fragment>{obtenerValorConfig(dataOtorgante, "CIUDAD_LEGAL_DEL_OTORGANTE")} a {new Intl.DateTimeFormat("es-MX", {
                                        year: "numeric",
                                        month: "long",
                                        day: "2-digit"
                                    }).format(new Date())}</Fragment>)}
                                </strong>
                            </small>
                        </h6>
                        {printText()}
                    </p>
                    <div className="terms_bg_box_gradient"></div>
                </div>
            </div>
            <div className="check_container">
                <form>
                    <div className="checkbox">
                        <label htmlFor="check_terms">
                            <input id="check_terms" type="checkbox" onChange={e => aceptarTerminos(e.target.checked)} />
                            <span className="checkbox-decorator"><span className="check"></span></span>
                            He leído y acepto "{obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_1")}".
                        </label>
                    </div>
                </form>
            </div>
            <br/>
            <br/>
            <br/>
            <Footer />
        </div>
    )
}

export default Terminos