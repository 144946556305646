import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import Footer from "../components/footer";
import { configStyle, obtenerValorConfig } from '../services/configStyle'

const Ayuda = () => {
	const location = useLocation();
	const [dataOtorgante, setDataOtorgante] = useState([])
	const [uuid, setUuid] = useState("")
	const [pass, setPass] = useState(false)
	const { ruta } = useParams();

	useEffect(() => {
		let dataOtorganteLS = localStorage.getItem("dataOtorgante")
		if (dataOtorganteLS != null) {
			setDataOtorgante(JSON.parse(dataOtorganteLS))
			configStyle(JSON.parse(dataOtorganteLS))
		}
		let uuidUser = localStorage.getItem("uuidUser")
		if (uuidUser != null) {
			setUuid(uuidUser)
		}
		if(location.state){
            if (location.state.passport) {
                setPass(true)
                //console.log("ayudapass true");
            }else{
                setPass(false)
                //console.log("ayudapass false");
            }
        }
		//console.log(ruta);

	}, []);

	useEffect(() => {
		configStyle(dataOtorgante)
	}, [dataOtorgante])

	return (
		<div className="main_gradient">
			<div id="head_container">
				<Link to={ (pass) ? 
						{
							pathname: '/' + ruta,
							state: { passport: pass  }
						}
						:
						(localStorage.getItem("externalReference")) ? 
							'/' + ruta + "?referer=" + localStorage.getItem("externalReference")
							:
							'/' + (ruta !== "pdf_token" ? ruta : `pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&fecha=${localStorage.getItem("fechavigencia")}`)} className="help_back"><img src={process.env.PUBLIC_URL + '/images/help_back.png'
				} alt="" /></Link>
				<img className="comp_logo" src={(obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', "") != "") ? obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', process.env.PUBLIC_URL + '/images/wl/comp_logo.png') : process.env.PUBLIC_URL + '/images/wl/comp_logo.png'} alt="" />
			</div>
			<div className="main_text_container">
				<h1 className="animated">Ayuda y soporte</h1>
				<p className="animated">Si <b>necesitas ayuda</b> durante el proceso o tienes <b>algún problema</b> con el uso de la plataforma <b>elige la opción</b> más conveniente para ti.</p>
				<br />
				<div className="help_container">
					<p className="help_mail"><b>Escríbenos a:</b>
						<br />
						<a href={"mailto:" + obtenerValorConfig(dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a>
					</p>
					<p className="help_phone"><b>Llámanos al:</b>
						<br />
						<a href={"tel:" + obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a>
					</p>
					<p className="help_whats"><b>Hablemos en:</b>
						<br />
						<a rel="external" href={"https://wa.me/0525567068439?text=Tuve%20problemas%20en%20el%20proceso%2C%20mi%20ID%20de%20transacci%C3%B3n%20es%20%3A%20" + uuid.split("-")[0]}>WhatsApp</a>
					</p>
					<p className="help_whats"><b>ID de transacción:</b>
						<br />
						<b>{uuid.split("-")[0]}</b>
					</p>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default Ayuda