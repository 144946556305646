import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import { actualizarEstado } from '../services/api.js';
import {status, statusError} from '../services/data'

const Clausula = () => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [showCancel, setShowCancel] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const { ruta } = useParams();

    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        status("clausulas_page", "Cláusula")
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    
    const statusE = () => {
        //console.log("entre a status cancelar");
        let data = "Cancelado"
        statusError("Cláusula", data, "cancelado")
            setTimeout(() => {
                window.close()
                history.push("/" + (ruta !== "pdf_video_token" ? apiKey : `pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&fecha=${localStorage.getItem("fechavigencia")}`))
            }, 300);
    }

    return (
        <Fragment>
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => setShowCancel(false)} className="btn btn-secondary modal_btn_w" data-dismiss="modal">NO</button>
                                <button type="button" onClick={e=>statusE()} className="btn btn-raised btn-secondary modal_btn_w">SI</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            <div className="main_gradient">
                <Header ruta="clausula" />
                <div className="main_text_container">
                    <h1 className="animated">{(obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_2", "FALSO") === "FALSO") ? ("") : obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_2")}</h1>
                    <p className="animated">Lee <b>atentamente</b> el documento que te presentaremos a <b>continuación</b> para proceder a su <b>aceptación</b></p>
                </div>
                <div className="main_icon_container animated">
                    <img src="../images/wl/doc_check.png" alt="" />
                </div>
                <div className="action_buttons noscroll_screen">
                <button onClick={e => setShowCancel(true)} className="btn btn-primary forcewidth100 main_color">
                        CANCELAR</button>
                    <Link to="/validacion_documento" className="btn btn-raised btn-primary forcewidth100 main_bg_color">LEER DOCUMENTO</Link>
                </div>
                <Footer />
            </div>
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
        </Fragment>
    )

}

export default Clausula