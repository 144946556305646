import React, { Fragment } from 'react'
const Loader = () => {
    return (
        <Fragment>
            <div className="loader_container animated delay-4s">
                <div className="ispinner ispinner-large">
                    <div className="ispinner-blade"></div>
                    <div className="ispinner-blade"></div>
                    <div className="ispinner-blade"></div>
                    <div className="ispinner-blade"></div>
                    <div className="ispinner-blade"></div>
                    <div className="ispinner-blade"></div>
                    <div className="ispinner-blade"></div>
                    <div className="ispinner-blade"></div>
                    <div className="ispinner-blade"></div>
                    <div className="ispinner-blade"></div>
                    <div className="ispinner-blade"></div>
                    <div className="ispinner-blade"></div>
                </div>
            </div>
        </Fragment>
    );
}

export default Loader;