import React, { useState, useEffect } from "react";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle, obtenerValorConfig } from '../services/configStyle';
import { isChrome, isEdge, isMobile, isFirefox, isIOS, isSafari } from 'react-device-detect';

const PdfTokenError = () => {
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [uuid, setUuid] = useState("")
    const [browserDnotSupported, setNotSupported] = useState(false);
    const [browsernotSupportediOS, setNotSupportediOS] = useState(false);

    useEffect(() => {
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
            configStyle(JSON.parse(dataOtorganteLS))
        }
        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
            //console.log(uuid);
        }
        let logo = (obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', ""));
        ////console.log("logo", logo);
        document.getElementById("imagenLogo").src = logo;
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        if (!isMobile && !isEdge && !isChrome) {
            //console.log("desktop not supported browsers");
            setNotSupported(true);
        } else if (isMobile && isIOS && !isSafari) {
            //console.log("mobile not supported browsers in iOS");
            setNotSupportediOS(true);
        } else if (isMobile && !isIOS && (!isFirefox  && !isChrome)) {
            //console.log("Android, not supported browser");
            setNotSupported(true);
        }
    }, [])

    return (
        <div className="main_gradient">
            <Header ruta="pdf_token_intructions" />
            <div className="main_text_container">
                <h1 className="animated fadeIn delay-1s">Resultado de proceso</h1>

                <p className="animated fadeIn delay-2s">
                    Lo sentimos la vigencia de este link caduco el día <b>{new Intl.DateTimeFormat("es-MX", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit"
                }).format(new Date(Number(localStorage.getItem("fechavigencia"))))}</b> si deseas mayor información comunícate con tu folio: <b> {localStorage.getItem("uuidUser").split("-")[0]}</b> al número: <a href="#!" className="main_color"><b>5512345678</b></a>
                </p>

            </div>
            <div className="action_buttons noscroll_screen">
                <button type="button" className="btn btn-raised btn-primary forcewidth100 main_bg_color animated fadeIn delay-3s">CONTINUAR</button>
            </div>
            <Footer />
            {((browserDnotSupported) ? <div className="browser_not_comp show_me_bx">
            <div className="top_bx">
                <img src="images/browser_alert_icon.svg" alt="" />  
                <h4>Lo sentimos tu navegador actual no es <br />                        
                compatible con nuestra plataforma.</h4>
                <div className="divider"></div>
            </div>
            <div className="bottom_bx">
                <p><small>Te sugerimos utilizar o descargar alguna de estas opciones.</small></p>                    
                <div className={["browser_bx", (isMobile ? "ios_browser" : "")].join(" ")}>
                    <img src="images/browser_chrome.png" width="96" alt="" />
                    <a href="https://www.google.com/chrome/">Chrome</a>
                    <small>Google</small>
                </div>
                <div className={["browser_bx", (isMobile ? "ios_browser" : "")].join(" ")}>
                    <img src="images/browser_edge.png" width="96" alt="" />
                    <a href="https://www.microsoft.com/en-us/edge">Edge</a>
                    <small>Microsoft</small>
                </div>
            </div>
        </div> : (""))}
        {( (browsernotSupportediOS) ? <div className="browser_not_comp show_me_bx"> 
            <div className="top_bx">
                <img src="images/browser_alert_icon.svg" alt="" />
                <h4>Lo sentimos tu navegador actual no es <br />
                compatible con nuestra plataforma.</h4>
                <div className="divider"></div>
            </div>
            <div className="bottom_bx">
                <p><small>Te sugerimos utilizar o descargar alguna de estas opciones.</small></p>
                <div className="browser_bx ios_browser">
                    <img src="images/browser_ios.png" width="96" alt="" />
                    <a href="#!">Safari</a>
                    <small>Apple</small>
                </div>
            </div>        
        </div> : (""))}
        </div>
    )

}

export default PdfTokenError