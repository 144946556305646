import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import { actualizarEstado, getDocuments } from '../services/api.js';
import { sendData, evento } from '../services/data';

const Finalizado = () => {
    const history = useHistory();
    const [apiKey, setApiKey] = useState('')
    const [uuid, setUuid] = useState("")
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [hasPhone, setHasPhone] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
        }
        //localStorage.setItem("completado", "true");
        setTimeout(() => {
            dataLinks()
        }, 200);

        setTimeout(() => {
            history.push('/finalizado');
        }, 6000)
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])


    const dataLinks = async () => {
        //console.log(event_t);
        let tipo = '';
        let informacion = {};
        getDocuments().then((response) => {
            if (response.status === 200) {
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Obtener documentos', 'Success', informacion, true);
                if (response.data.payload.links) {
                    let asd = JSON.stringify(response.data.payload.links)
                    localStorage.setItem('links', asd);
                }
                finalUpdateStatus();
            }
        }).catch(( error ) => {
            //console.log("algun error documents");
            //setLoading(false)
            finalUpdateStatus();
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Obtener documentos', tipo, informacion, false);
        })
    }

    // const status = () => {
    //     if (window.opener) {
    //         let uuidUser = localStorage.getItem("uuidUser");////uuid.split("-")[0]
    //         let event_t = "OK"
    //         let res = convertdataJSON();
    //         //console.log("entre a status finish", uuidUser);
    //         if (localStorage.getItem("movil")) {
    //             event_t = (res !== "") ? JSON.stringify(res) : ""
    //             window.opener.postMessage(JSON.stringify({
    //                 step: "completed",
    //                 result: "FAIL",
    //                 data: event_t
    //             }), localStorage.getItem("externalReference"))
    //         } else {
    //             //event_t = (res !== "") ? JSON.stringify(res) : ""
    //             window.opener.postMessage(JSON.stringify({
    //                 step: "completed",
    //                 result: event_t,
    //                 uuid: uuidUser,
    //                 data: (res !== "") ? JSON.stringify(res) : "",
    //             }), localStorage.getItem("externalReference"))
    //         }

    //         setTimeout(() => {
    //             window.close();
    //         }, 2000);
    //     }
    // }

    const finalUpdateStatus = async () => {
        let res = convertdataJSON();
        let event_t = JSON.stringify(res)
        var json_final = {};
        let statu = "OK"
        if (hasPhone) {
            statu = "FAIL"
        }
        json_final = {
            "status": statu,
            "step": "finished",
            "description": event_t,
            "Completo": true
        }
        let event = JSON.stringify(json_final);
        sendData({ 'step': 'finalizado', 'event': event });
        localStorage.setItem("completado", "true");
        // status();
    }

    const convertdataJSON = () => {
        var jsonObj = {};
        jsonObj.uuidTrx = localStorage.getItem("uuidTrx") || "";
        jsonObj.uuidUser = localStorage.getItem("uuidUser") || "";
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || "";
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || "";
        jsonObj.data_user = JSON.parse(localStorage.getItem("data_user")) || "";
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || "";
        jsonObj.detailsScore = JSON.parse(localStorage.getItem('fields')) || "";
        if (hasPhone) {
            jsonObj.number_phone = JSON.parse(localStorage.getItem("movil")) || ""
        }
        //var res = JSON.stringify(jsonObj)
        //console.log(res);
        return jsonObj;
    }

    return (
        <div className="main_gradient">
            <Header ruta="finalizado" />
            <div className="main_text_container">
                {loading && <Fragment>
                    <div className="loader_container animated delay-4s top_35_p">
                        <div className="ispinner ispinner-large">
                            <div className="ispinner-blade"></div>
                            <div className="ispinner-blade"></div>
                            <div className="ispinner-blade"></div>
                            <div className="ispinner-blade"></div>
                            <div className="ispinner-blade"></div>
                            <div className="ispinner-blade"></div>
                            <div className="ispinner-blade"></div>
                            <div className="ispinner-blade"></div>
                            <div className="ispinner-blade"></div>
                            <div className="ispinner-blade"></div>
                            <div className="ispinner-blade"></div>
                            <div className="ispinner-blade"></div>
                        </div>
                    </div>
                </Fragment>}
            </div>
            <div className="main_icon_container top_30_p">
                <p>Espera, estoy revisando tus datos</p>
            </div>
            <Footer />
        </div>
    )

}

export default Finalizado