import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle } from '../services/configStyle'
import {hayExcepcion,status, statusError} from '../services/data'

const PreparacionIne = () => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [showCancel, setShowCancel] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const { ruta } = useParams();

    useEffect(() => {

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        status("preparacion_id_page", "Preparacion ID")
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const statusE = () => {
        let data = "Cancelado"
        statusError("Preparacion ID", data, "cancelado")
        setTimeout(() => {
            history.push("/" + (ruta !== "pdf_video_token" ? apiKey : `pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&fecha=${localStorage.getItem("fechavigencia")}`))
        }, 300);
    }

    return (
        <Fragment>
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => setShowCancel(false)} className="btn btn-secondary modal_btn_w" data-dismiss="modal">NO</button>
                                <button type="button" onClick={e=>statusE()} className="btn btn-raised btn-secondary modal_btn_w">SI</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            <div className="main_gradient">
                <Header ruta="preparacion_identificacion" />
                {(hayExcepcion()) ? 
                    <div>
                        <div className="main_text_container">
                        <h1 className="animated">Captura tu Documento Nacional de Identidad</h1>
                        <p className="animated">Para capturar tu DNI asegúrate que se encuentre <b>vigente</b>, evita <b>reflejos</b>, de preferencia frente a una <b>superficie obscura</b> para mejorar el contraste.</p>
                        </div>
                        <div className="row id_type_bx">
                            <div className="col-12">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <h6>DNI</h6>
                                    <div className="maskface id_central"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="main_text_container">
                        <h1 className="animated">Captura tu identificación oficial</h1>
                        <p className="animated">Para capturar tu identificación asegúrate que se encuentre <b>vigente</b>, evita <b>reflejos</b>, de preferencia frente a una <b>superficie obscura</b> para mejorar el contraste.</p>
                        </div>
                        <div className="row id_type_bx">
                            <div className="col-5">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <h6>INE/IFE - FMM</h6>
                                    <div className="maskface id_central"></div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <img className="mid_sep" src="images/mid_sep_central.png" height="100" alt="" />
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <h6>Pasaporte</h6>
                                    <div className="maskface pass_central"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                } 
                <div className="action_buttons noscroll_screen">
                    <button onClick={e => setShowCancel(true)} className="btn btn-primary forcewidth100 main_color">
                        CANCELAR</button>
                    <Link to={{
                        pathname: "/identificacion",
                        state: { passport: false }
                    }} className="btn btn-raised btn-primary forcewidth100 main_bg_color">
                        CAPTURAR IDENTIFICACIÓN</Link>
                    
                </div>
                <Footer />
            </div>
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
        </Fragment>
    )

}


export default PreparacionIne