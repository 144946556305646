/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import 'video.js/dist/video-js.css';
import 'webrtc-adapter';
import { configStyle, obtenerValorConfig } from '../services/configStyle';
import Loader from "../components/loader";
import Typist from 'react-typist';
import { verificarTokenVideo, enviarImagen, enviarVideoRostro, getDocuments } from '../services/api';
import { isIOS, isMobileSafari, isFirefox, isMobile, isIPad13, withOrientationChange } from 'react-device-detect';
import ConnectionClient from '../services/ConnectionClientWebRTC';
import { status, statusError, statusData, statusReintento, evento, flowIncompleted, enviarObjetoATD, statusBlocked } from '../services/data';
import RecordRTC from 'recordrtc';

const bucket = process.env.REACT_APP_BUCKET;

const msjLuz = "Por favor busca una posición con menos luz directa en tu rostro al momento de la captura."
const msjObscuridad = "Por favor busca un lugar con más luz al momento de la captura."
const msjCubrebocas = "Parece que algún objeto obstruye tu rostro, por favor retíralo y captura nuevamente."
const msjRostro = "Por favor busca una posición con menos luz directa y centra tu rostro en el área marcada al momento de la captura."
const msjMultiple = "Parece que hay más de un rostro en la toma, asegúrate que no haya nadie mas al momento de la captura."
const msjBorrosa = "Por favor asegurate que tu cámara esta limpia y mantén firme tu dispositivo para evitar distorsiones en la imagen."
const msjFrase = "No logramos entender la frase, por favor lee FUERTE y CLARO e intenta nuevamente."
const msjFrase2 = "No logramos entender la frase, por favor lee FUERTE y CLARO e intenta nuevamente."

class CameraVideoTokenIOS extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        loading: false,
        token: null,
        isRecording: false,
        isComplete: false,
        isStart: true,
        uuid: null,
        errorToken: false,
        dataUser: [],
        dataOtorgante: [],
        codes: [],
        face: {},
        errorMessage: "",
        intents: 0,
        apikey: "",
        hasResponse: false,
        conteo: false,
        codec: "video/webm;codecs=vp8",
        errorF: "",
        errorVideo: false,
        streamStarted: false,
        pathname: "",
        suportMediaRecorder: true,
        uuidTrx: '',
    }

    componentDidMount() {

        if (isFirefox && !isMobile) {
            this.state.codec = "video/webm;codecs=vp9"
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            this.setState({ apikey: apikeyOtorgante })
        }

        let codesLS = localStorage.getItem("codes")
        if (codesLS !== null) {
            this.setState({ codes: JSON.parse(codesLS) });
        }

        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            this.setState({ uuid: uuidUser })
        }

        let uuidTrx = localStorage.getItem("uuidTrx")
        if (uuidTrx) {
            this.setState({ uuidTrx: uuidTrx })
        }

        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            this.setState({ dataUser: JSON.parse(dataUserLS) })
        }

        this.state.pathname = window.location.pathname;

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            this.setState({ dataOtorgante: JSON.parse(dataOtorganteLS) })
            configStyle(dataOtorganteJSON)
        }

        let bloqueo = localStorage.getItem("bloqueo");
        if (bloqueo === null) {
            localStorage.setItem("bloqueo", "false");
        } else {
            if (bloqueo == 'true') {
                this.state.intents = 5;
                //console.log("intentsFront: " + this.state.intentsFront);
            }
        }
        //console.log("bloque: " + bloqueo);

        this.localStream = window.navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
        }).catch(error => {
            if (error.name == "NotFoundError" || error.name == "DevicesNotFoundError") {
                //required track is missing 
                evento('Captura Video Token', 'User Media', { error: error.name, status: 'NO SE ENCONTRO DISPOSITIVO Y/O TRACK' }, true);
            } else if (error.name == "NotReadableError" || error.name == "TrackStartError") {
                //webcam or mic are already in use 
                evento('Captura Video Token', 'User Media', { error: error.name, status: 'LOS DISPOSITVOS SOLICITADOS ESTÁN EN USO' }, true);
            } else if (error.name == "OverconstrainedError" || error.name == "ConstraintNotSatisfiedError") {
                //constraints can not be satisfied by avb. devices 
                evento('Captura Video Token', 'User Media', { error: error.name, status: 'EL DISPOSITIVO NO PUEDE ALCANZAR LOS CONSTRAINTS' }, true);
            } else if (error.name == "NotAllowedError" || error.name == "PermissionDeniedError") {
                //permission denied in browser 
                evento('Captura Video Token', 'User Media', { error: error.name, status: 'PERMISOS DENEGADOS' }, true);
            } else if (error.name == "TypeError" || error.name == "TypeError") {
                //empty constraints object 
                evento('Captura Video Token', 'User Media', { error: error.name, status: 'CONSTRAINTS VACÍOS' }, true);
            } else {
                //other errors 
                evento('Captura Video Token', 'User Media', { error: error.toString(), status: 'OTRO TIPO DE ERROR' }, true);
            }
            that.setState({ errorVideo: true })
        });

        status("captura_video_token_page", "Captura Video Token")

        this.canvas = document.querySelector('canvas');
        this.width = 320;
        this.height = 0;
        this.video = document.querySelector('video');
        this.elemento = document.getElementById("cuadro");
        this.cuenta = document.getElementById("numeros");
        this.titulo = document.getElementById("head_shop");
        let component = this;
        this.localStream.then(function (mediaStream) {
            component.video.srcObject = mediaStream;
            component.video.onloadedmetadata = function (e) {
                component.video.play();
                if (mediaStream.getVideoTracks().length > 0) {
                    component.setState({ streamStarted: true, errorVideo: false })
                    if (window.MediaRecorder) {
                        let mymeType = null
                        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
                            mymeType = 'video/webm; codecs=vp9';
                        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
                            mymeType = 'video/webm; codecs=vp8';
                        }
                        let type = isIOS ? 'video/mp4' : mymeType;
                        const optionNormal = {
                            type: 'video',
                            mimeType: type,
                        };

                        component.recorder = new RecordRTC(mediaStream, optionNormal);
                    } else {
                        component.setState({ suportMediaRecorder: false });
                    }
                } else {
                    component.setState({ errorVideo: true })
                }
            };
            component.video.addEventListener('canplay', function (ev) {
                component.height = component.video.videoHeight / (component.video.videoWidth / component.width);
                component.canvas.setAttribute('width', component.video.videoWidth * 1.5);
                component.canvas.setAttribute('height', component.video.videoHeight * 1.5);
            }, false);
        }).catch(e => {
            console.trace(e);
            this.setState({ errorVideo: true });
        });
    }

    takepicture = () => {
        evento('Captura Video Token', 'WebRTC', { description: 'OK' }, true);
        let tipo = '';
        let informacion = {};
        setTimeout(() => {
            //console.log("tomando foto");
            let contextCanvas = this.canvas.getContext('2d');
            contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
            const that = this;
            this.canvas.toBlob((blob) => {
                enviarImagen(that.state.uuid, blob)
                    .then((response) => {
                        if (response.status === 200) {
                            informacion.status = response.status;
                            informacion.payload = response.data.payload;
                            evento('OTP', 'Success', informacion, true);
                            //console.log(response.data.payload.codes);
                            let listCodes = response.data.payload.codes;//.map((elemento, indice) => { return { used: false, token: elemento, expire: new Date().getTime(), created: new Date().getTime() } });
                            that.setState({ codes: listCodes });
                            localStorage.setItem("codes", JSON.stringify(listCodes));

                            setTimeout(async () => {
                                that.cuenta.innerHTML = "";
                                that.cuenta.className = "token_txt animated";
                                that.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro</p>";
                                that.setState({ isStart: false, isRecording: true, isComplete: false, conteo: true })

                                let tokenLS = listCodes.pop();
                                //console.log(tokenLS);
                                that.setState({ token: tokenLS });
                                localStorage.setItem("codes", JSON.stringify(listCodes));
                                const token = tokenLS.code;
                                const connectionClient = new ConnectionClient();
                                let peerConnection1 = null;

                                const localVideo = document.getElementById('video_wrt_environment');
                                localVideo.autoplay = true;
                                localVideo.muted = true;

                                let tipo = '';
                                let informacion = {};

                                async function beforeAnswer(peerConnection) {
                                    that.localStream.then(function (mediaStream) {
                                        mediaStream.getTracks().forEach(track => peerConnection.addTrack(track, mediaStream));
                                    })
                                    peerConnection1 = peerConnection;
                                    let dataChannel = null;
                                    let interval = null;
                                    let timeoutt = null;
                                    function onMessage({ data }) {
                                        const responseWRT = JSON.parse(data);
                                        if (responseWRT.status === 200) {
                                            informacion.status = responseWRT.status;
                                            informacion.payload = responseWRT.res.payload;
                                            evento('Captura Video Token WebRTC', 'Success', informacion, true);
                                            if (responseWRT.res.payload.match) {
                                                that.setState({ loading: false })
                                                that.setLocalStorageResultFace(responseWRT.res.payload)
                                                that.sendData(responseWRT.res.payload.match)
                                                setTimeout(() => {
                                                    that.props.history.push("/consulta_historial");
                                                }, 300);
                                            } else {
                                                that.setState({ errorMessage: responseWRT.res.message_client, errorToken: true, errorF: responseWRT.res.message_client, token: null })
                                                if (that.state.intents === Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3"))) {
                                                    that.finalizarFlujo(informacion);
                                                } else {
                                                    that.statusR();
                                                }
                                            }
                                            that.setState({ loading: false, intents: 0, hasResponse: true })
                                            peerConnection.close()
                                        } else if (responseWRT.status === 500 || responseWRT.status === 400) {
                                            let errorMessage = that.msjsError(responseWRT.res.message_client);
                                            if (responseWRT.res.message_client === 'No se encontro rostro' || responseWRT.res.message_client === 'Se detecto cubrebocas' || responseWRT.res.message_client === 'Condiciones de luz no adecuadas' || responseWRT.res.message_client === 'Imagen borrosa' || responseWRT.res.message_client === 'Se detectaron multiples caras' || responseWRT.res.message_client === 'Falta de luz' || responseWRT.res.message_client === 'No se pudo realizar la comparacion de rostro') {
                                                that.setState({ errorMessage: errorMessage, errorToken: true, errorF: errorMessage, token: null, loading: false, hasResponse: true })
                                            } else {
                                                that.setState({ errorMessage: errorMessage, errorToken: true, errorF: errorMessage, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                            }
                                            informacion.mensaje = responseWRT.res.message_client;
                                            informacion.status = responseWRT.status;
                                            evento('Captura Video Token WebRTC', 'Error', informacion, false);
                                            if (that.state.intents === Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3"))) {
                                                that.finalizarFlujo(informacion);
                                            } else {
                                                that.statusR();
                                            }
                                            peerConnection.close()
                                        } else if (responseWRT.status === 404) {
                                            let errorMessage = that.msjsError(responseWRT.res.message_client);
                                            that.setState({ errorMessage: errorMessage, errorToken: true, errorF: responseWRT.res.message_client, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true });
                                            informacion.mensaje = responseWRT.res.message_client;
                                            informacion.status = responseWRT.status;
                                            evento('Captura Video Token WebRTC', 'Error', informacion, false);
                                            if (that.state.intents === Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3"))) {
                                                that.finalizarFlujo(informacion);
                                            }
                                            else {
                                                that.statusR();
                                            }

                                            if (responseWRT.res.message_client === "Spoof detected") {
                                                that.setState({ intents: Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3")) });
                                            }
                                            peerConnection.close()
                                        } else {
                                            that.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve", errorToken: true, errorF: "Error en la captura", token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                            informacion.mensaje = responseWRT.res.message_client;
                                            informacion.status = responseWRT.status;
                                            evento('Captura Video Token WebRTC', 'Error', informacion, false);
                                            if (that.state.intents === Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3"))) {
                                                that.finalizarFlujo(informacion);
                                            } else {
                                                that.statusR();
                                            }
                                            peerConnection.close();

                                        }
                                        that.titulo.innerHTML = "<p>Por favor <b>lee</b> el <b>texto</b> que <b>aparecerá</b> aquí en:</p>";
                                        that.cuenta.className = "cnt_dwn animated";
                                        localVideo.classList.remove("blur_video");
                                    }
                                    let intentos = 0
                                    function onDataChannel({ channel }) {
                                        if (channel.label !== 'ping-pong') {
                                            return;
                                        }
                                        dataChannel = channel;
                                        dataChannel.addEventListener('message', onMessage);
                                        interval = setInterval(() => {
                                            intentos++;
                                            if (intentos === 1) {
                                                //titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro</p>";
                                                that.cuenta.className = "token_nmbr animated fadeIn";
                                                that.cuenta.innerHTML = token.substring(0, 1);
                                            }
                                            if (intentos === 2) {
                                                that.cuenta.className = "token_nmbr animated fadeIn";
                                                that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2);
                                            }
                                            if (intentos === 3) {
                                                that.cuenta.className = "token_nmbr animated fadeIn";
                                                that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3);
                                            }
                                            if (intentos === 4) {
                                                that.cuenta.className = "token_nmbr animated fadeIn";
                                                that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3) + " " + token.substring(3, 4);
                                            }
                                            if (intentos === 8) {
                                                that.setState({ isRecording: false, isComplete: true, loading: true, hasResponse: false });
                                                dataChannel.send("finish_record");
                                                localVideo.classList.add("blur_video")
                                                that.setState({ loading: false, hasResponse: false })
                                                that.titulo.innerHTML = "<p>Gracias por tu <b>paciencia</b></p>";
                                                that.cuenta.innerHTML = "";
                                                that.cuenta.className = "";
                                            }
                                        }, 1000);
                                        timeoutt = setTimeout(() => {
                                            dataChannel.send(JSON.stringify({ uuid: that.state.uuid, token: that.state.token, bucket: bucket, ios: isIOS, mobilesafari: isMobileSafari, isIPad: isIPad13, type: "VIDEO_TOKEN", isMobile: isMobile, key: localStorage.getItem("apikeyOtorgante"), transaccion: (localStorage.getItem("uuidTrx")), cliente: (localStorage.getItem("uuidUser")), service: 'videotoken-embedding' }));
                                        }, 500);
                                    }
                                    peerConnection.addEventListener('datachannel', onDataChannel);
                                    const { close } = peerConnection;
                                    peerConnection.close = function () {
                                        if (dataChannel) {
                                            dataChannel.removeEventListener('message', onMessage);
                                        }
                                        if (interval) {
                                            clearInterval(interval);
                                        }
                                        if (timeoutt) {
                                            clearTimeout(timeoutt)
                                        }
                                        return close.apply(this, arguments);
                                    };
                                }

                                peerConnection1 = await connectionClient.createConnection({ beforeAnswer: beforeAnswer })
                                window.peerConnection1 = peerConnection1;
                            }, 1000)


                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            let { data: { message }, status } = error.response
                            informacion.mensaje = message;
                            informacion.status = status;
                            tipo = 'Error';
                            if (error.error_code === 'SVC_ERROR_CONNECT"') {
                                that.setState({ errorMessage: "El servicio no se encuentra disponible.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true, stable: false })
                            } else {
                                that.setState({ errorMessage: "Por favor asegúrate de retirar tu cubreboca, lentes y evita colocarte de espaldas a una fuente de luz.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true, stable: false })
                            }
                        } else {
                            informacion.error = error.toString();
                            tipo = 'Exception';
                            that.setState({ errorMessage: "Por favor ubica tu rostro en el área marcada para continuar.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true, stable: false })
                        }
                        evento('OTP', tipo, informacion, false);
                        if (that.state.intents === Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3")))
                            that.finalizarFlujo(informacion);
                        else
                            that.statusR();
                    })

            }, 'image/jpeg', 0.9);
        }, 3000);
    }

    obtenerToken = async (reIntento) => {
        let description = reIntento ? 'REINTENTAR' : 'HAGÁMOSLO';
        this.sendEventClick(description, {});
        if ((this.state.errorMessage).includes("embedings")) {
            localStorage.removeItem("codes")
        }
        this.setState({ loading: false, errorToken: false, errorMessage: "" })
        //console.log("entre a obtener");
        //this.checkCamera()

        if (localStorage.getItem("bloqueo") === "false") {
            /*             obtenerTokenVideo(this.state.uuid).then(async (response) => {
                            if (response.status === 200) { */
            this.setState({ conteo: true, isStart: false })
            this.elemento.classList.add("slideInDown");
            this.cuenta.classList.add("fadeIn");
            this.cuenta.classList.add("delay-1s");
            this.cuenta.innerHTML = '<img src="images/sand_clock_b.gif" height="50"alt="" />';

            if (!this.state.codes.length) {
                if (this.state.suportMediaRecorder) {
                    this.takePhotoAndroid();
                } else {
                    this.takepicture();
                }
            } else if (!this.state.suportMediaRecorder) {
                setTimeout(async () => {
                    this.cuenta.innerHTML = "";
                    this.cuenta.className = "token_txt animated";
                    this.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro</p>";
                    this.setState({ isStart: false, isRecording: true, isComplete: false, conteo: true });

                    let tokens_ls = JSON.parse(localStorage.getItem("codes"));
                    let tokenLS = tokens_ls.pop();
                    this.setState({ token: tokenLS });
                    localStorage.setItem("codes", JSON.stringify(tokens_ls));
                    const token = tokenLS.code;
                    const connectionClient = new ConnectionClient();
                    let peerConnection2 = null;
                    const that = this;

                    const localVideo = document.getElementById('video_wrt_environment');
                    localVideo.autoplay = true;
                    localVideo.muted = true;

                    async function beforeAnswer(peerConnection) {
                        that.localStream.then(function (mediaStream) {
                            mediaStream.getTracks().forEach(track => peerConnection.addTrack(track, mediaStream));
                        })
                        peerConnection2 = peerConnection;
                        let dataChannel = null;
                        let interval = null;
                        let timeoutt = null;
                        let tipo = '';
                        let informacion = {};
                        function onMessage({ data }) {
                            const responseWRT = JSON.parse(data);
                            if (responseWRT.status === 200) {
                                informacion.status = responseWRT.status;
                                informacion.payload = responseWRT.res.payload;
                                evento('Captura Video Token WebRTC', 'Success', informacion, true);
                                if (responseWRT.res.payload.match) {
                                    that.setState({ loading: false })
                                    that.setLocalStorageResultFace(responseWRT.res.payload)
                                    that.sendData(responseWRT.res.payload.match)
                                    setTimeout(() => {
                                        that.props.history.push("/consulta_historial")
                                    }, 300);
                                } else {
                                    that.setState({ errorMessage: responseWRT.res.message_client, errorToken: true, errorF: responseWRT.res.message_client, token: null })
                                    if (that.state.intents === Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3"))) {
                                        that.finalizarFlujo(informacion);
                                    }
                                    else {
                                        that.statusR();
                                    }
                                }
                                that.setState({ loading: false, intents: 0, hasResponse: true })
                                peerConnection.close()
                            } else if (responseWRT.status === 500 || responseWRT.status === 400) {
                                let errorMessage = that.msjsError(responseWRT.res.message_client);
                                if (responseWRT.res.message_client === 'No se encontro rostro' || responseWRT.res.message_client === 'Se detecto cubrebocas' || responseWRT.res.message_client === 'Condiciones de luz no adecuadas' || responseWRT.res.message_client === 'Imagen borrosa' || responseWRT.res.message_client === 'Se detectaron multiples caras' || responseWRT.res.message_client === 'Falta de luz' || responseWRT.res.message_client === 'No se pudo realizar la comparacion de rostro') {
                                    that.setState({ errorMessage: errorMessage, errorToken: true, errorF: errorMessage, token: null, loading: false, hasResponse: true })
                                } else {
                                    that.setState({ errorMessage: errorMessage, errorToken: true, errorF: errorMessage, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                }
                                informacion.mensaje = responseWRT.res.message_client;
                                informacion.status = responseWRT.status;
                                evento('Captura Video Token WebRTC', 'Error', informacion, false);
                                if (that.state.intents === Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3"))) {
                                    that.finalizarFlujo(informacion);
                                } else {
                                    that.statusR();
                                }
                                peerConnection.close()
                            } else if (responseWRT.status === 404) {
                                let errorMessage = that.msjsError(responseWRT.res.message_client);
                                that.setState({ errorMessage: errorMessage, errorToken: true, errorF: responseWRT.res.message_client, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                informacion.mensaje = responseWRT.res.message_client;
                                informacion.status = responseWRT.status;
                                evento('Captura Video Token WebRTC', 'Error', informacion, false);
                                if (that.state.intents === Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3"))) {
                                    that.finalizarFlujo(informacion);
                                } else {
                                    that.statusR();
                                }
                                if (responseWRT.res.message_client === "Spoof detected") {
                                    that.setState({ intents: Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3")) });
                                }
                                peerConnection.close();
                            } else {
                                that.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve", errorToken: true, errorF: "Error en la captura", token: null, loading: false, intents: that.state.intents + 1, hasResponse: true });
                                informacion.mensaje = responseWRT.res.message_client;
                                informacion.status = responseWRT.status;
                                evento('Captura Video Token WebRTC', 'Error', informacion, false);
                                if (that.state.intents === Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3")))
                                    that.finalizarFlujo(informacion);
                                else
                                    that.statusR();
                                peerConnection.close()
                            }
                            that.titulo.innerHTML = "<p>Por favor <b>lee</b> el <b>texto</b> que <b>aparecerá</b> aquí en:</p>";
                            that.cuenta.className = "cnt_dwn animated";
                            localVideo.classList.remove("blur_video")
                        }
                        let intentos = 0
                        function onDataChannel({ channel }) {
                            if (channel.label !== 'ping-pong') {
                                return;
                            }
                            dataChannel = channel;
                            dataChannel.addEventListener('message', onMessage);
                            interval = setInterval(() => {
                                intentos++;
                                if (intentos === 1) {
                                    //titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro</p>";
                                    that.cuenta.className = "token_nmbr animated fadeIn";
                                    that.cuenta.innerHTML = token.substring(0, 1);
                                }
                                if (intentos === 2) {
                                    that.cuenta.className = "token_nmbr animated fadeIn";
                                    that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2);
                                }
                                if (intentos === 3) {
                                    that.cuenta.className = "token_nmbr animated fadeIn";
                                    that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3);
                                }
                                if (intentos === 4) {
                                    that.cuenta.className = "token_nmbr animated fadeIn";
                                    that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3) + " " + token.substring(3, 4);
                                }
                                if (intentos === 6) {
                                    that.setState({ isRecording: false, isComplete: true, loading: true, hasResponse: false });
                                    dataChannel.send("finish_record");
                                    localVideo.classList.add("blur_video")
                                }
                                if (intentos === 7) {
                                    that.setState({ loading: false, hasResponse: false })
                                    that.titulo.innerHTML = "<p>Gracias por tu <b>paciencia</b></p>";
                                    that.cuenta.innerHTML = "";
                                    that.cuenta.className = "";
                                }
                            }, 1000);
                            timeoutt = setTimeout(() => {
                                dataChannel.send(JSON.stringify({ uuid: that.state.uuid, token: that.state.token, bucket: bucket, ios: isIOS, mobilesafari: isMobileSafari, isIPad: isIPad13, type: "VIDEO_TOKEN", isMobile: isMobile, key: localStorage.getItem("apikeyOtorgante"), transaccion: (localStorage.getItem("uuidTrx")), cliente: (localStorage.getItem("uuidUser")), service: 'videotoken-embedding' }));
                            }, 500);
                        }
                        peerConnection.addEventListener('datachannel', onDataChannel);
                        const { close } = peerConnection;
                        peerConnection.close = function () {
                            if (dataChannel) {
                                dataChannel.removeEventListener('message', onMessage);
                            }
                            if (interval) {
                                clearInterval(interval);
                            }
                            if (timeoutt) {
                                clearTimeout(timeoutt)
                            }
                            return close.apply(this, arguments);
                        };
                    }

                    peerConnection2 = await connectionClient.createConnection({ beforeAnswer: beforeAnswer })
                    window.peerConnection2 = peerConnection2;
                }, 1000)
            } else {
                let codigosLS = JSON.parse(localStorage.getItem("codes"));
                let tokenLS = codigosLS.pop();
                this.setState({ token: tokenLS });
                localStorage.setItem("codes", JSON.stringify(codigosLS));

                const token = tokenLS.code;
                const Acreated = tokenLS.created;
                const Aexpire = tokenLS.expire;

                const localVideo = document.getElementById('video_wrt_environment');
                localVideo.autoplay = true;
                localVideo.muted = true;

                setTimeout(async () => {
                    this.cuenta.innerHTML = "";
                    this.cuenta.className = "token_txt animated";
                    this.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro</p>";
                    this.setState({ isStart: false, isRecording: true, isComplete: false, conteo: true });

                    setTimeout(() => {
                        console.log("indicamos que empiece a grabar despues de 1seg");
                        this.recorder.startRecording();
                        let intentos = 0;
                        let interval = setInterval(() => {
                            intentos++;
                            if (intentos === 1) {
                                this.cuenta.className = "token_nmbr animated fadeIn";
                                this.cuenta.innerHTML = token.substring(0, 1);
                            }
                            if (intentos === 2) {
                                this.cuenta.className = "token_nmbr animated fadeIn";
                                this.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2);
                            }
                            if (intentos === 3) {
                                this.cuenta.className = "token_nmbr animated fadeIn";
                                this.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3);
                            }
                            if (intentos === 4) {
                                this.cuenta.className = "token_nmbr animated fadeIn";
                                this.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3) + " " + token.substring(3, 4);
                            }
                            if (intentos === 7) {
                                this.setState({ isRecording: false, isComplete: true, loading: true, hasResponse: false });

                                localVideo.classList.add("blur_video")
                                this.setState({ loading: false, hasResponse: false })
                                this.titulo.innerHTML = "<p>Gracias por tu <b>paciencia</b></p>";
                                this.cuenta.innerHTML = "";
                                this.cuenta.className = "";
                                this.recorder.stopRecording(() => {
                                    let blobvideo = this.recorder.getBlob();
                                    this.sendVideoToken(blobvideo, Acreated, Aexpire, isIOS)
                                });
                            }
                            if (intentos === 8) {
                                //let blob = this.recorder.getBlob();
                                // this.downloadVideo(blob);   
                                clearInterval(interval);
                            }
                        }, 1000);
                    }, 1000);
                }, 1000);
            }
        } else {
            this.setState({ isStart: false })
        }
    }

    verificarToken = async (video) => {
        this.setState({ loading: true, hasResponse: false })
        let tipo = '';
        let informacion = {};
        verificarTokenVideo(this.state.uuid, this.state.token, video, isIOS, isMobileSafari).then((response) => {
            console.warn(response);
            if (response.status === 200) {
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Verifica token', 'Success', informacion, true);
                if (response.data.payload.match) {
                    this.setState({ loading: false })
                    this.setLocalStorageResultFace(response.data.payload)
                    this.sendData(responseWRT.res.payload.match)
                    setTimeout(() => {
                        this.props.history.push("/consulta_historial")
                    }, 300);
                } else {
                    this.setState({ errorMessage: response.data.payload.message, errorToken: true, errorF: "Error en la captura", token: null })
                    if (this.state.intents === 3) {
                        this.finalizarFlujo(informacion);
                    } else {
                        this.statusR();
                    }
                }
            }
        }).catch((error) => {
            if (error.response.hasOwnProperty('status')) {
                if (error.response.status === 500) {
                    this.setState({ errorMessage: error.response.data.payload.message })
                } else if (error.response.status === 404) {
                    this.setState({ errorMessage: error.response.data.payload.message })
                    if (error.response.data.payload.message === "Spoof detected") {
                        this.setState({ intents: 5 });
                    }
                } else {
                    this.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve" })
                }
                informacion.mensaje = responseWRT.res.message_client;
                informacion.status = responseWRT.status;
                evento('Verifica token', 'Error', informacion, false);
            } else {
                this.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve" })
            }
            /* if (this.state.intents === 5) {
                this.setState({ errorMessage: "Lo sentimos \n Haz superado el número máximo de intents para este proceso inténtalo nuevamente más tarde, recuerda estar en un lugar silencioso y tomar en cuenta las instrucciones." })
                setTimeout(() => {
                    this.props.history.push("/" + this.state.apikey)
                }, 3000);
            } */
            this.setState({ errorToken: true, errorF: "Error en la captura", token: null })
            informacion.mensaje = responseWRT.res.message_client;
            informacion.status = responseWRT.status;
            evento('Verifica token', 'Error', informacion, false);
            if (that.state.intents === 3) {
                that.statusF();
                that.finalizarFlujo(informacion);
            }
            else {
                that.statusR();
            }
            console.error(error.response)

        }).finally(() => {
            this.setState({ loading: false, intents: this.state.intents + 1, hasResponse: true })
        })
    }

    setLocalStorageResultFace = (result) => {
        let face = {}
        //console.log("responseWRT: ", result);
        if (result.match) {
            face = {
                match: result.match,
                // gender: (result.faceComparison.payload.Query[0].gender) === "male" ? "Masculino" : "Femenino",
                // age: result.faceComparison.payload.Query[0].age.toFixed(0),
                // expressions: result.faceComparison.payload.Query[0].expressions,
                liveness: result.liveness
            }
        } else {
            face = {
                match: false,
                gender: "Indeterminado",
                age: 0,
                expressions: {
                    happy: 0.0
                },
                liveness: "No superada"
            };
        }//liveness
        localStorage.setItem("face", JSON.stringify(face));
    };


    stopRecord = () => {
        //this.player.record().stop();
    };

    stopAnimation = () => {
        this.state.token = null;
    };

    // destroy player on unmount
    componentWillUnmount() {
        if (this.localStream) {
            this.localStream.then(function (mediaStream) {
                if (mediaStream) mediaStream.getTracks().forEach(track => track.stop());
            });
            this.localStream = null;
        }
        window.removeEventListener("beforeunload", () => {//console.log("adios 🐶");
        });
    }

    onTypingDone = () => {
        setTimeout(() => {
            //setStateActionE(false);
            document.getElementsByClassName("kroke_bx")[0].style.display = "none"
            this.setState({ loading: true });
        }, 1000)
    }

    sendData = (match) => {
        statusData("captura_video_token", { coincidencia: match, speech: true })
    }

    statusE = () => {
        //console.log("entre a status cancelar");
        statusError("Captura Video Token", this.state.errorF, "cancelado")
        setTimeout(() => {
            window.close()
        }, 300);
    }

    intentosBloqueo = () => {
        localStorage.setItem("bloqueo", "true");
        this.statusE();
        let newDate = new Date().getTime();
        let acceptDta = new Date();
        acceptDta.setTime(newDate + (60 * 60 * 1000))
        localStorage.setItem("time", acceptDta.getTime)
        //console.log("BLOQUEO: " + localStorage.getItem("bloqueo"));
        //console.log("BLOQUEO: " + localStorage.getItem("bloqueo"));

    }

    sendVideoToken = (blob, Acreated, Aexpire, isIOS) => {
        let tipo = '';
        let informacion = {};
        enviarVideoRostro(blob, Acreated, Aexpire, isIOS)
            .then(({ status, data: { payload } }) => {
                if (status === 200) {
                    informacion.status = status;
                    informacion.payload = payload;
                    evento('Captura Video Token', 'Success', informacion, true);
                    this.setState({ loading: false })
                    this.setLocalStorageResultFace(payload);
                    this.sendData(payload.match);
                    setTimeout(() => {
                        this.props.history.push("/consulta_historial");
                    }, 300);
                }
            })
            .catch((error) => {
                if (error.response) {
                    let { data: { message_client }, status } = error.response
                    informacion.mensaje = message_client;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Captura Video Token', tipo, informacion, false);
                if (error.response) {
                    let mensaje = "";
                    let errorMessage ='';
                    if (error.response.data) {
                        mensaje = error.response.data.message_client ? error.response.data.message_client : error.response.data.message;
                    }
                    errorMessage = this.msjsError(mensaje);
                    if (error.response.status === 500 || error.response.status === 400) {
                        if (mensaje === 'No se encontro rostro' || mensaje === 'Se detecto cubrebocas' || mensaje === 'Condiciones de luz no adecuadas' || mensaje === 'Imagen borrosa' || mensaje === 'Se detectaron multiples caras' || mensaje === 'Falta de luz' || mensaje === 'No se pudo realizar la comparacion de rostro') {
                            this.setState({ errorMessage: errorMessage, errorToken: true, errorF: mensaje, token: null, loading: false, hasResponse: true })
                        } else {
                            this.setState({ errorMessage: errorMessage, errorToken: true, errorF: mensaje, token: null, loading: false, intents: this.state.intents + 1, hasResponse: true })
                        }
                    } else if (error.response.status === 404) {
                        this.setState({ errorMessage: errorMessage, errorToken: true, errorF: mensaje, token: null, loading: false, intents: this.state.intents + 1, hasResponse: true })
                    } else {
                        this.setState({ errorMessage: "Revisa tu conexión a internet e intenta nuevamente", errorToken: true, errorF: "Revisa tu conexión a internet e intenta nuevamente", token: null, loading: false, intents: this.state.intents + 1, hasResponse: true })
                    }
                    if (this.state.intents === Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3")))
                        this.finalizarFlujo(informacion);
                    else
                        this.statusR();
                } else {
                    if (error.toString().includes('Network')) {
                        this.setState({ errorMessage: "Revisa tu conexión a internet e intenta nuevamente", errorToken: true, errorF: "Revisa tu conexión a internet e intenta nuevamente", token: null, loading: false, hasResponse: true })
                    } else {
                        this.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve", errorToken: true, errorF: "Error en la captura", token: null, loading: false, hasResponse: true })
                    }
                }
                this.setState({ errorCapture: true });
                document.getElementById('video_wrt_environment').classList.remove("blur_video");
            })
    }

    printKaraoke = (phrase) => {
        return (<Fragment>
            {
                phrase.split("").map((element, index) => {
                    return <Typist
                        key={index + "typist"}
                        className="characterToken"
                        cursor={{
                            show: true,
                            blink: false,
                            element: '',
                            hideWhenDone: true,
                            hideWhenDoneDelay: 100,
                        }}>
                        <Typist.Delay ms={(index + 1) * 1000} />
                        <span className="mid_p"><b>{element}</b></span>
                    </Typist>
                })
            }
        </Fragment >)
    }

    statusF = () => {
        //console.log("entre a status ine");
        statusError("Captura Video Token", this.state.errorF, "error")
    }

    checkCamera = () => {
        setTimeout(() => {
            console.log("Camera:" + this.state.streamStarted);
            if (!this.state.streamStarted) {
                this.setState({ errorVideo: true })
            }
        }, 3500);
    }

    statusR = () => {
        statusReintento("Captura Video Token", this.state.errorF, "RETRY");
    }

    takePhotoAndroid = () => {
        evento('Captura Video Token', 'MediaRecorder', { status: 'OK' }, true);
        let tipo = '';
        let informacion = {};
        setTimeout(() => {
            let contextCanvas = this.canvas.getContext('2d');
            contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
            const that = this;
            this.canvas.toBlob((blob) => {
                enviarImagen(that.state.uuid, blob).then((response) => {
                    if (response.status === 200) {
                        informacion.status = response.status;
                        informacion.payload = response.data.payload;
                        evento('OTP', 'Success', informacion, true);
                        let listCodes = response.data.payload.codes;
                        that.setState({ codes: listCodes });
                        localStorage.setItem("codes", JSON.stringify(listCodes));
                        let tokenLS = listCodes.pop();
                        that.setState({ token: tokenLS });
                        localStorage.setItem("codes", JSON.stringify(listCodes));


                        const token = tokenLS.code;

                        const Acreated = tokenLS.created;
                        const Aexpire = tokenLS.expire;

                        const localVideo = document.getElementById('video_wrt_environment');
                        localVideo.autoplay = true;
                        localVideo.muted = true;

                        setTimeout(async () => {
                            that.cuenta.innerHTML = "";
                            that.cuenta.className = "token_txt animated";
                            that.titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro</p>";
                            that.setState({ isStart: false, isRecording: true, isComplete: false, conteo: true });

                            setTimeout(() => {
                                console.log("indicamos que empiece a grabar despues de 1seg");
                                that.recorder.startRecording();

                                let intentos = 0;
                                let interval = setInterval(() => {
                                    intentos++;
                                    if (intentos === 1) {
                                        that.cuenta.className = "token_nmbr animated fadeIn";
                                        that.cuenta.innerHTML = token.substring(0, 1);
                                    }
                                    if (intentos === 2) {
                                        that.cuenta.className = "token_nmbr animated fadeIn";
                                        that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2);
                                    }
                                    if (intentos === 3) {
                                        that.cuenta.className = "token_nmbr animated fadeIn";
                                        that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3);
                                    }
                                    if (intentos === 4) {
                                        that.cuenta.className = "token_nmbr animated fadeIn";
                                        that.cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3) + " " + token.substring(3, 4);
                                    }
                                    if (intentos === 7) {
                                        that.setState({ isRecording: false, isComplete: true, loading: true, hasResponse: false });

                                        localVideo.classList.add("blur_video")
                                        that.setState({ loading: false, hasResponse: false })
                                        that.titulo.innerHTML = "<p>Gracias por tu <b>paciencia</b></p>";
                                        that.cuenta.innerHTML = "";
                                        that.cuenta.className = "";
                                        that.recorder.stopRecording(() => {
                                            let blobvideo = that.recorder.getBlob();
                                            that.sendVideoToken(blobvideo, Acreated, Aexpire, isIOS)
                                        });
                                    }
                                    if (intentos === 8) {
                                        // that.downloadVideo(blob);    // indica que a la función downloadVideo le mande el blob obtenido 
                                        clearInterval(interval)         // limpiamos el intervalo para evitar que siga los ciclos y consuma memoria
                                    }
                                }, 1000);
                            }, 1000)
                        }, 2000);
                    }
                })
                    .catch((error) => {
                        let e = error.toString();
                        let { data: { message_client }, status } = error.response
                        informacion.mensaje = message_client;
                        informacion.status = status;
                        tipo = 'Error';
                        if (e.includes("500")) {
                            that.setState({
                                errorMessage: "Por favor asegúrate de retirar tu cubreboca, lentes y evita colocarte de espaldas a una fuente de luz.",
                                errorToken: true,
                                errorF: "Error en la captura de rostro",
                                token: null,
                                loading: false,
                                hasResponse: true
                            })
                        } else {
                            informacion.error = error.toString();
                            tipo = 'Exception';
                            that.setState({
                                errorMessage: "Por favor ubica tu rostro en el área marcada para continuar.",
                                errorToken: true,
                                errorF: "Error en la captura de rostro",
                                token: null,
                                loading: false,
                                hasResponse: true
                            })
                        }
                        evento('OTP', tipo, informacion, false);
                        if (that.state.intents === 3) {
                            that.finalizarFlujo(informacion);
                        }
                        else {
                            that.statusR();
                        }
                    })

            }, 'image/jpeg', 0.9);
        }, 3000);
    }

    msjsError = (mensaje) => {
        let eMessage = null
        switch (mensaje) {
            case 'No se encontro rostro':
                eMessage = msjRostro
                break;
            case 'Se detecto cubrebocas':
                eMessage = msjCubrebocas
                break;
            case 'Condiciones de luz no adecuadas':
                eMessage = msjLuz
                break;
            case 'Imagen borrosa':
                eMessage = msjBorrosa
                break;
            case 'Se detectaron multiples caras':
                eMessage = msjMultiple
                break;
            case 'No logramos entender la frase, intente nuevamente':
                eMessage = msjFrase
                break;
            case 'Falta de luz':
                eMessage = msjObscuridad
                break;
            case 'Error frase':
                eMessage = msjFrase2
                break;
            case 'No hay coincidencia intente nuevamente':
                eMessage = msjErrorEmbedings;
                this.setState({ retry: false });
                break;
            case 'Lo sentimos no hay coincidencia entre tu selfie registrada y la persona actual':
                eMessage = mensaje
                break;
            default:
                eMessage = msjRostro;
                break;
        }
        return eMessage;
    }

    sendEventClick = (description, objectInformation) => {
        let objeto = { description: description };
        let objetoEvento = {
            ...objeto,
            ...objectInformation
        }
        evento('Captura Video Token', 'Click', objetoEvento, true);
    }

    finalizarFlujo = (respuesta) => {
        let tipo = '';
        let informacion = {};
        getDocuments().then((response) => {
            informacion.payload = response.data.payload;
            evento('Obtener Documentos', 'Success', informacion, true);
            if (response.status === 200) {
                if (response.data.payload.links.length > 0) {
                    let links = JSON.stringify(response.data.payload.links);
                    localStorage.setItem('links', links);
                }
            }
            setTimeout(() => {
                enviarObjetoATD(false, true);
                this.finishLifCycle(respuesta);
            }, 300);
        }).catch((error) => {
            if (error.response) {
                tipo = 'Exception';
            }
            evento('Obtener Documentos', tipo, informacion, false);
            setTimeout(() => {
                this.finishLifCycle();
            }, 300);
            //console.log("algun error documents");
        })
    }

    convertdataJSON = () => {
        let jsonObj = {};
        jsonObj.uuidTrx = localStorage.getItem("uuidTrx") || "";
        jsonObj.uuidUser = localStorage.getItem("uuidUser") || '';
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
        jsonObj.data_user = JSON.parse(localStorage.getItem("data_user")) || {};
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || {};
        return jsonObj;
    }

    finishLifCycle = async (response) => {
        await sendZipFlag('SEMAFORO', 'red', isIOS);
        localStorage.setItem("bloqueo", "true");
        let newDate = new Date().getTime();
        let acceptDta = new Date();
        acceptDta.setTime(newDate + (60 * 60 * 1000))
        localStorage.setItem("time", acceptDta.getTime())
        let res = this.convertdataJSON();
        let event_t = await JSON.stringify(res);
        var json_final = {};
        let status = "BLOCK"
        json_final = {
            "status": status,
            "step": "finished",
            "description": event_t,
            'response': response,
            "Completo": true
        }
        let event = JSON.stringify(json_final);
        await flowIncompleted({ 'step': 'captura_video_token', 'event': event }, 'red');
    }


    render() {
        const { isLandscape } = this.props;
        return (
            <div>
                {(this.state.errorToken && this.state.intents < Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3"))) ?
                    <div className="modal fade show" style={{ display: "block" }} role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Algo sucedio</h5>
                                    <Link to="/cancelar_flujo/video_token" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </Link>
                                </div>
                                <div className="modal-body">
                                    <p>{this.state.errorMessage}</p>
                                    {/* <p>Lo sentimos <b>no pudimos</b> reconocer la <b>lectura del texto</b>, por favor <b>intenta de nuevo</b> o cancela para abandonar el proceso.</p> */}
                                </div>
                                <div className="modal-footer">
                                    <Link to="/cancelar_flujo/video_token" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.sendEventClick('CANCELAR', {})}>Cancelar</Link>
                                    <button type="button" onClick={()=> this.obtenerToken(true)} className="btn btn-primary btn-success">Reintentar</button>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                <div className="module_container overflow_hddn" style={{ position: 'fixed' }}>
                    <div className="camera_capture_frame speech_mod">
                        <video src="" id="video_wrt_environment" playsInline muted></video>
                        <canvas id="canvas_wrt_environment" hidden></canvas>
                        {/*  <div data-vjs-player>
                            <video id="myVideo2" ref={node => this.videoNode = node} className={["video-js", "video-js", ((this.state.isComplete) ? "blur_video" : "")].join(" ")} playsInline></video>
                        </div> */}
                    </div>
                    <div className="module_gradident_overlay"></div>
                    <div className="module_title animated slideInDown">
                        {(this.state.isComplete) ?
                            (<p>Gracias por tu <b>paciencia</b></p>) : (<p>Ubica tu <b>rostro</b> dentro de la <b>guía</b></p>)}
                    </div>
                    <img className="id_mask animated fadeIn delay-2s" src={process.env.PUBLIC_URL + '/images/face_id_on.svg'} alt="" />
                    <div className="align_alert hide_elmnt"><p>Mantén tu rostro dentro del área definida para iniciar la lectura.</p>
                    </div>

                    <div className="recorder_container">
                        {/* {(this.state.isComplete) ?
                            <div className="recorder_box">
                                <div className="circle_back" onClick={this.obtenerToken} >
                                    <div className="circle_front"></div>
                                </div>
                                <div className="audio_meter_box">
                                    <ul>
                                        <li className="b_a"></li>
                                        <li className="b_b"></li>
                                        <li className="b_c"></li>
                                        <li className="b_d"></li>
                                        <li className="b_e"></li>
                                        <li className="b_f"></li>
                                        <li className="b_g"></li>
                                        <li className="b_h"></li>
                                        <li className="b_i"></li>
                                        <li className="b_j"></li>
                                        <li className="b_k"></li>
                                        <li className="b_l"></li>
                                    </ul>
                                </div>
                            </div> : ("")} */}

                        {/* {(this.state.isRecording) ? <div>
                            <div className="recorder_box">
                                <div className="circle_back recording_state" onClick={this.stopRecord}>
                                    <img className="hide_elmnt" src={process.env.PUBLIC_URL + '/images/warning_36.png'} width="35" alt="" />
                                    <div className="circle_front animated infinite pulse"></div>
                                </div>
                                <div className="audio_meter_box">
                                    <ul className="rec_state">
                                        <li className="b_a"></li>
                                        <li className="b_b anim_b"></li>
                                        <li className="b_c anim_c"></li>
                                        <li className="b_d anim_d"></li>
                                        <li className="b_e anim_e"></li>
                                        <li className="b_f anim_f"></li>
                                        <li className="b_g anim_g"></li>
                                        <li className="b_h anim_h"></li>
                                        <li className="b_i anim_i"></li>
                                        <li className="b_j anim_j"></li>
                                        <li className="b_k anim_k"></li>
                                        <li className="b_l"></li>
                                    </ul>
                                </div>
                            </div>
                        </div> : ("")} */}
                    </div>
                    {/* {(this.state.isRecording) ? <div className="status_txt">
                        <p>escuchando</p>
                    </div> : ("")} */}
                </div>
                {(this.state.isStart) ? <Fragment> <div className="overlay_box">
                    <div className="alert_box_bottom animated slideInDown">
                        <div className="background_atd">
                            <div className="padding_5">
                                <p className="alert_title"><b>Captura tu rostro</b></p>
                                <p className="alert_text">Evita hacerlo a contraluz con lentes o gorra y lee el número que aparece en pantalla</p>
                                <button type="button" onClick={() => this.obtenerToken(false)} className="btn btn-raised btn-primary forcewidth100 main_bg_color  animated fadeIn delay-2s border_button_20 alert_button">HAGÁMOSLO</button>
                            </div>
                        </div>
                    </div>
                </div>
                </Fragment> : ("")}

                <div id="cuadro" className="red_flag_karaoke vsbl_bx animated">
                    <div id="head_shop" className="head_shop">
                        <p>Por favor <b>lee</b> el <b>texto</b> que <b>aparecerá</b> aquí en:</p>
                    </div>
                    <div className="center_nmbrs">
                        <div className="nmbr_bx">
                            <p id="numeros" className=""></p>
                        </div>
                    </div>
                    <div className="red_flg"><img src="images/red_flag.svg" alt="" /></div>
                </div>

                {this.state.intents >= Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_VIDEO_TOKEN", "3")) ?
                    (<div className="err_finale  animated slideInUp">
                        <div className="center_checks">
                            <h5>Proceso no exitoso</h5>
                            <p>Haz superado el número <b>máximo</b> de intentos para este proceso inténtalo nuevamente <b>más tarde</b>.</p>
                            <p>Si deseas mayor información no dudes en contactarnos con el <b>ID</b> de proceso <b>{(this.state.uuidTrx).split("-")[0]}</b> </p>
                            <br />
                            {
                                (this.state.pathname === "/Cw2qjkHFRvpLPryvUWXDcOPMEgHIYI9X") ?
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <p><b>Llámanos al:</b></p>
                                                <p><a className="help_phone" href={"tel:" + obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a></p>
                                            </div>
                                            <div className="col">
                                                <p><b>Escríbenos a:</b></p>
                                                <p><a className="help_mail" href={"mailto:" + obtenerValorConfig(this.state.dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""
                            }
                            <div className="action_buttons noscroll_screen">
                                {
                                    <Link to={"/" + this.state.apikey} className="btn btn-raised btn-primary forcewidth100 main_bg_color border_button_20" onClick={() => this.sendEventClick('ENTENDIDO', {})}>ENTENDIDO</Link>
                                }
                            </div>
                        </div>
                    </div>) : ("")}
                {(this.state.isComplete && !this.state.hasResponse) ? <Loader /> : ("")}
                {
                    (isLandscape) ? (
                        <div className="rotate_device">
                            <div className="center_info_bx">
                                <img src="images/rotate_device.svg" height="100" alt="" />
                                <p>Por favor usa tu dispositivo en vertical<br />
                                    <small>Gira tu dispositivo para continuar</small>
                                </p>
                            </div>
                        </div>) : ("")
                }
                {(this.state.errorVideo) ?
                    <div className="overlay_box">
                        <div className="alert_box_bottom animated slideInDown">
                            <div className="background_atd">
                                <div className="padding_5">
                                    <p className="alert_text">Hemos detectado que la <b>cámara de tu dispositivo</b> está siendo usada por otra web en <b>alguna pestaña</b>, por favor asegúrate de <b>cerrar las pestañas</b> abiertas e inténtalo nuevamente.</p>
                                    <button type="button" onClick={(e) => {
                                        this.setState({ errorVideo: false });
                                        this.sendEventClick('VOLVER A INTENTAR', {});
                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 400);
                                        //this.props.history.push("/identificacion")
                                    }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animated fadeIn delay-2s border_button_20">VOLVER A INTENTAR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
                }
            </div>
        );
    }
}
CameraVideoTokenIOS = withOrientationChange(CameraVideoTokenIOS);
export default withRouter(CameraVideoTokenIOS);