import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle } from '../services/configStyle';
import { crearSMS, validarSMSServicio } from '../services/api.js'
import Loader from "../components/loader";
import {hayExcepcion, statusData, statusError, status} from '../services/data'

const ValidarSMS = () => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [uuid, setUuid] = useState("")
    const [apiKey, setApiKey] = useState('')
    const [codigo, setCodigo] = useState('')
    const [focusCodigo, setFocusCodigo] = useState(false)
    const [changeStyleValue, setChangeStyleValue] = useState(false)
    const [showCancel, setShowCancel] = useState(false)
    const [movil, setMovil] = useState('')
    const [errorSMS, setErrorSMS] = useState('')
    const [loading, setLoading] = useState(false);
    const [btnReSend, setbtnReSend] = useState(false)

    useEffect(() => {
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
            configStyle(JSON.parse(dataOtorganteLS))
        }
        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
        }
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }
        let movile = localStorage.getItem("movil")
        //console.log("movil: ", movile)
        setMovil(movile);
        setTimeout(() => {
            setbtnReSend(true)
        }, 30000);

        status("sms_check_page", "Validación sms");
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const enviarMovil = async () => {
        setLoading(true)
        //console.log("El número móvil es: ",movil)
        let area= "52";
        if(hayExcepcion()){
            area = "51"
        }
        crearSMS(uuid, movil, area).then((response) => {
            if (response.status === 200) {
                setTimeout(() => {
                    //console.log("Se reenvió con éxito el código SMS");
                }, 200);
            }
            else {
                //console.log("Ocurrió un error al generar el SMS");
                setErrorSMS(response.error);
            }
        }).catch((err) => {
            console.error(err);
            setErrorSMS("Ocurrió un error al validar el SMS, vuelve a intentar");
            //console.log("algun error");
        }).finally(() => {
            setLoading(false)
        }) 
    }

    const validarSMS = async () => {
        setLoading(true)
        setbtnReSend(true)
        //console.log("El código es: ", codigo);
        let area= "52";
        if(hayExcepcion()){
            area = "51"
        }
        validarSMSServicio(uuid, movil, area, codigo).then((response) => {
            let data = ""
            if (response.status === 200) {
                statusData("sms_check", {number_phone: movil})
                    /*if (window.opener) { 
                    let event_t = "FAIL"
                        window.opener.postMessage(JSON.stringify({
                            step: "completed",
                            result: event_t,
                            data: {number_phone: movil},
                            type: "PROCESS",
                            error_text: "El proceso no se concluyo correctamente"
                        }), localStorage.getItem("externalReference"))
                        setTimeout(() => {
                            window.close();
                        }, 1500);
                    }else{*/
                        setTimeout(() => {
                            history.push('/finalizado');
                        }, 400);
                    //}
                
            }
            else {
                //console.log("Ocurrió un error al validar el SMS");
                data = response.error;
                setErrorSMS(response.error);
                statusError("sms_check", {error: response.error}, "error")
            }
        }).catch((err) => {
            console.error(err);
            setErrorSMS("Ocurrió un error al validar el SMS, vuelve a intentar");
            document.getElementById("exampleInputCode").value = "";
            //console.log("algun error",err.error);
            statusError("sms_check", {error: err.error}, "error")
        }).finally(() => {
            setLoading(false)
        }) 
    }

    const changeStyle = () => {
        setChangeStyleValue(true)
        //console.log(changeStyleValue);
    }

    const enableFullScreenSpace = () => {
        setChangeStyleValue(false)
        //console.log(changeStyleValue);
    }

    return (
        <Fragment>
        {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => setShowCancel(false)} className="btn btn-secondary modal_btn_w" data-dismiss="modal">NO</button>
                                <button type="button" onClick={e => history.push('/' + apiKey)} className="btn btn-raised btn-secondary modal_btn_w">SI</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}

        <div className="main_gradient">
            <Header ruta="validar_sms" />
            <div class="main_text_container">
                <h1>Verificación móvil</h1>
                <p>Hemos enviado un <b>código de verificación</b> al número <b>{movil}</b> ingrésalo para terminar.</p>
            </div>
            <div class="ocr_data_display">
                <form>
                  <div class={["form-group", "bmd-form-group", ((focusCodigo || codigo !== "") ? "is-focused" : "")].join(" ")}>
                    <label for="exampleInputCode" class="bmd-label-floating">Código:</label>
                    <input type="text" class="form-control" id="exampleInputCode" defaultValue={codigo} onChange={e => setCodigo(e.target.value)} onFocus={e => {
                                setFocusCodigo(true)
                                changeStyle()
                                //setInputActive(true)
                                setErrorSMS("")
                            }} onBlur={e => {
                                enableFullScreenSpace()
                                if (codigo === "") {
                                    setFocusCodigo(false)
                                }
                                //setInputActive(false)
                            }} />
                  </div>
                  <div><p class="textogral">{errorSMS}</p></div>
                  <div class="centerButtom">
                    <button type="button" class={(!btnReSend) ? "btn btn-primary main_color hide_elmnt" : "btn btn-primary main_color"} onClick={ enviarMovil }><small>Reenviar código</small></button>
                  </div>
                </form>
            </div>
            <div class="action_buttons noscroll_screen">
                <button type="button" class="btn btn-primary forcewidth100 main_color" onClick={e => setShowCancel(true)} >CANCELAR</button>
                <button type="button" onClick={e => validarSMS()} disabled={!codigo} class={(codigo) ? "btn btn-raised btn-primary forcewidth100 main_bg_color" : "btn btn-raised btn-secondary forcewidth100"}>VALIDAR CÓDIGO</button>
            </div>
            <Footer />
            {(loading) ? <Loader /> : ("")}
        </div>
        </Fragment>
    )

}

export default ValidarSMS