/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import 'video.js/dist/video-js.css';
import 'webrtc-adapter';
import { configStyle, obtenerValorConfig} from '../services/configStyle';
import Loader from "../components/loader";
import { obtenerTokenPDFVideo, verificarTokenPDFVideo } from '../services/api';
import { isIOS, isMobileSafari, isFirefox, isMobile, isIPad13, withOrientationChange } from 'react-device-detect';
import ConnectionClient from '../services/ConnectionClientWebRTC';

const bucket = process.env.REACT_APP_BUCKET;

class PDFVideoToken extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        loading: false,
        token: null,
        isRecording: false,
        isComplete: false,
        isStart: true,
        uuid: null,
        errorToken: false,
        dataUser: [],
        dataOtorgante: [],
        face: {},
        errorMessage: "",
        intents: 0,
        apikey: "",
        hasResponse: false,
        codec: "video/webm;codecs=vp8"
    }

    componentDidMount() {
        if (isFirefox && !isMobile) {
            this.state.codec = "video/webm;codecs=vp9"
        }

        let apikeyOtorgante = localStorage.getItem("idOtorgante")
        if (apikeyOtorgante !== null) {
            this.setState({ apikey: apikeyOtorgante })
        }

        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            this.setState({ uuid: uuidUser })
        }

        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            this.setState({ dataUser: JSON.parse(dataUserLS) })
        }


        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            this.setState({ dataOtorgante: JSON.parse(dataOtorganteLS) })
            configStyle(dataOtorganteJSON)
        }

        this.localStream = window.navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
        });

        let bloqueo = localStorage.getItem("bloqueo");
        if (bloqueo === null) {
            localStorage.setItem("bloqueo", "false");
        } else {
            if (localStorage.getItem("bloqueo") == 'true') {
                this.state.intents = 5;
            }
        }

        this.localStream.then(function (mediaStream) {
            var video = document.querySelector('video');
            video.srcObject = mediaStream;
            video.onloadedmetadata = function (e) {
                video.play();
            };
        })
    }

    obtenerToken = async () => {
        this.setState({ loading: false, errorToken: false, errorMessage: "" })
        if (localStorage.getItem("bloqueo") === "false") {
            obtenerTokenPDFVideo(this.state.uuid).then(async (response) => {
                if (response.status === 200) {
                    this.setState({ conteo: true, isStart: false })
                    let elemento = document.getElementById("cuadro");
                    elemento.className += " slideInDown";
                    let cuenta = document.getElementById("numeros");
                    cuenta.className += " fadeIn delay-1s";
                    cuenta.innerHTML = '<img src="images/sand_clock.gif_b" height="50"alt="" />';

                    setTimeout(() => {
                        setTimeout(() => {
                            //cuenta.innerHTML = "2";
                            setTimeout(() => {
                                //cuenta.innerHTML = "1";
                                setTimeout(async () => {
                                    cuenta.innerHTML = "";
                                    cuenta.className = "token_txt animated";
                                    let titulo = document.getElementById("head_shop");
                                    //titulo.innerHTML = "<p>Por favor <b>lee</b> el siguiente texto</p>";
                                    titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro</p>";
                                    this.setState({ token: response.data.payload, isStart: false, isRecording: true, isComplete: false, conteo: true })
                                    //const listWord = this.state.token.token.split(" ");
                                    /*const texto = "Valido el código de seguridad "+ this.state.token.token;
                                    const listWord = texto.split(" ");
                                    const countWord = listWord.length;
                                    let line = "";
                                    let lines = [];
                                    listWord.forEach((element, index) => {
                                        line = line + " " + element;
                                        if ((index + 1) % 3 === 0) {
                                            lines.push(line.trim());
                                            line = "";
                                        } else if (countWord - 1 === index + 1) {
                                            lines.push(line.trim());
                                            line = "";
                                        }
                                    });
                                    const token = lines.pop();*/
                                    const token = this.state.token.code;
                                    const connectionClient = new ConnectionClient();
                                    let peerConnection1 = null;
                                    const that = this;

                                    const localVideo = document.getElementById('video_wrt_environment');
                                    localVideo.autoplay = true;
                                    localVideo.muted = true;

                                    async function beforeAnswer(peerConnection) {
                                        //cuenta.innerHTML = lines.join("<br>");
                                        that.localStream.then(function (mediaStream) {
                                            mediaStream.getTracks().forEach(track => peerConnection.addTrack(track, mediaStream));
                                        })
                                        peerConnection1 = peerConnection
                                        let dataChannel = null;
                                        let interval = null;

                                        let timeoutt = null;

                                        function onMessage({ data }) {

                                            const responseWRT = JSON.parse(data);
                                            console.log(responseWRT);

                                            if (responseWRT.status === 200) {
                                                if (responseWRT.res.payload.match) {
                                                    that.setState({ loading: false })
                                                    that.setLocalStorageResultFace(responseWRT.res.payload)
                                                    //console.log("x:",responseWRT.res);
                                                    localStorage.setItem("enlace", responseWRT.res.payload.documentLink);
                                                    setTimeout(() => {
                                                        that.props.history.push("/pdf_token_result")
                                                    }, 300);
                                                } else {
                                                    that.setState({ errorMessage: responseWRT.res.message_client, errorToken: true, token: null })
                                                }
                                                that.setState({ loading: false, intents: 0, hasResponse: true })
                                                peerConnection.close()
                                            } else if (responseWRT.status === 500 || responseWRT.status === 400) {
                                                that.setState({ errorMessage: responseWRT.res.message_client, errorToken: true, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                                peerConnection.close()
                                            } else if (responseWRT.status === 404) {
                                                that.setState({ errorMessage: responseWRT.res.message_client, errorToken: true, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })

                                                if (responseWRT.res.message_client === "Spoof detected") {
                                                    that.setState({ intents: Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3")) });
                                                }
                                                peerConnection.close()
                                            } else {
                                                that.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve", errorToken: true, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                                peerConnection.close()
                                            }
                                            titulo.innerHTML = "<p>Por favor <b>lee</b> el <b>texto</b> que <b>aparecerá</b> aquí en:</p>";
                                            cuenta.className = "cnt_dwn animated";
                                            localVideo.classList.remove("blur_video")

                                        }
                                        let intentos = 0
                                        function onDataChannel({ channel }) {
                                            if (channel.label !== 'ping-pong') {
                                                return;
                                            }

                                            dataChannel = channel;
                                            dataChannel.addEventListener('message', onMessage);

                                            interval = setInterval(() => {
                                                intentos++;
                                                if (intentos === 1) {
                                                    //titulo.innerHTML = "<p>Por favor <b>lee</b> cada <b>número</b> fuerte y claro</p>";
                                                    cuenta.className = "token_nmbr animated fadeIn";
                                                    cuenta.innerHTML = token.substring(0, 1);
                                                }
                                                if (intentos === 2) {
                                                    cuenta.className = "token_nmbr animated fadeIn";
                                                    cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2);
                                                }
                                                if (intentos === 3) {
                                                    cuenta.className = "token_nmbr animated fadeIn";
                                                    cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3);
                                                }
                                                if (intentos === 4) {
                                                    cuenta.className = "token_nmbr animated fadeIn";
                                                    cuenta.innerHTML = token.substring(0, 1) + " " + token.substring(1, 2) + " " + token.substring(2, 3) + " " + token.substring(3, 4);
                                                }
                                                if (intentos === 7) {
                                                    that.setState({ isRecording: false, isComplete: true, loading: true, hasResponse: false });
                                                    dataChannel.send("finish_record");
                                                    localVideo.classList.add("blur_video")
                                                }
                                                if (intentos === 7) {
                                                    that.setState({ loading: false, hasResponse: false })
                                                    titulo.innerHTML = "<p>Gracias por tu <b>paciencia</b></p>";
                                                    cuenta.innerHTML = "";
                                                    cuenta.className = "";
                                                }
                                            }, 1000);
                                            timeoutt = setTimeout(() => {
                                                dataChannel.send(JSON.stringify({ uuid: that.state.uuid, token: that.state.token, bucket: bucket, ios: isIOS, mobilesafari: isMobileSafari, isIPad: isIPad13, type: "VIDEO_TOKEN_PDF", isMobile: isMobile, key: localStorage.getItem("idOtorgante"), transaccion: (localStorage.getItem("uuidTrx")), cliente: (localStorage.getItem("uuidUser")), service:'videotoken-pdf' }));
                                            }, 500);
                                        }
                                        peerConnection.addEventListener('datachannel', onDataChannel);
                                        const { close } = peerConnection;
                                        peerConnection.close = function () {

                                            if (dataChannel) {
                                                dataChannel.removeEventListener('message', onMessage);
                                            }
                                            if (interval) {
                                                clearInterval(interval);
                                            }
                                            if (timeoutt) {
                                                clearTimeout(timeoutt)
                                            }

                                            return close.apply(this, arguments);
                                        };
                                    }

                                    peerConnection1 = await connectionClient.createConnection({ beforeAnswer: beforeAnswer })
                                    window.peerConnection1 = peerConnection1;
                                }, 200)

                            }, 1000)
                        }, 1000)
                    }, 1000)
                }
            }).catch((err) => {
                this.setState({ loading: false })
            }).finally(() => {

            })
        } else {
            this.setState({ isStart: false })
        }
    }

    verificarToken = async (video) => {
        this.setState({ loading: true, hasResponse: false })
        verificarTokenPDFVideo(this.state.uuid, this.state.token, video, isIOS, isMobileSafari).then((response) => {
            
            if (response.status === 200) {
                if (response.data.payload.match) {
                    this.setState({ loading: false })
                    this.setLocalStorageResultFace(response.data)
                    setTimeout(() => {
                        this.props.history.push("/consulta_historial")
                    }, 300);
                } else {
                    this.setState({ errorMessage: response.data.message, errorToken: true, token: null })
                }
            }
        }).catch((error) => {
            if (error.response.hasOwnProperty('status')) {
                if (error.response.status === 500) {
                    this.setState({ errorMessage: error.response.data.message })
                } else if (error.response.status === 404) {
                    this.setState({ errorMessage: error.response.data.message })
                    if (error.response.data.message === "Spoof detected") {
                        this.setState({ intents: 5 });
                    }
                } else {
                    this.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve" })
                }
            } else {
                this.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve" })
            }
            /* if (this.state.intents === 5) {
                this.setState({ errorMessage: "Lo sentimos \n Haz superado el número máximo de intents para este proceso inténtalo nuevamente más tarde, recuerda estar en un lugar silencioso y tomar en cuenta las instrucciones." })
                setTimeout(() => {
                    this.props.history.push("/" + this.state.apikey)
                }, 3000);
            } */
            this.setState({ errorToken: true, token: null })
            
        }).finally(() => {
            this.setState({ loading: false, intents: this.state.intents + 1, hasResponse: true })
        })
    }

    setLocalStorageResultFace = (result) => {
        let face = {}
        if (result.match) {
            face = {
                match: result.match,
                gender: (result.faceComparison.payload.Query[0].gender) === "male" ? "Masculino" : "Femenino",
                age: result.faceComparison.payload.Query[0].age.toFixed(0),
                expressions: result.faceComparison.payload.Query[0].expressions,
                liveness: result.liveness
            }
        } else {
            face = {
                match: false,
                gender: "Indeterminado",
                age: 0,
                expressions: {
                    happy: 0.0
                },
                liveness: "No superada"
            };
        }//liveness
        localStorage.setItem("face", JSON.stringify(face));
    };


    stopRecord = () => {
        //this.player.record().stop();
    };

    stopAnimation = () => {
        this.state.token = null;
    };

    // destroy player on unmount
    componentWillUnmount() {
        if (this.localStream) {
            this.localStream.then(function (mediaStream) {
                mediaStream.getTracks().forEach(track => track.stop());
            });
            this.localStream = null;
        }
    }

    onTypingDone = () => {
        setTimeout(() => {
            //setStateActionE(false);
            document.getElementsByClassName("kroke_bx")[0].style.display = "none"
            this.setState({ loading: true });
        }, 1000)
    }

    render() {
        const { isLandscape } = this.props;
        return (
            <div>
                {(this.state.errorToken && this.state.intents < Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3"))) ?
                    <div className="modal fade show" style={{ display: "block" }} role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Algo sucedio</h5>
                                    <Link to="/cancelar_flujo/pdf_video_token" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </Link>
                                </div>
                                <div className="modal-body">
                                    <p>{this.state.errorMessage}</p>
                                    {/* <p>Lo sentimos <b>no pudimos</b> reconocer la <b>lectura del texto</b>, por favor <b>intenta de nuevo</b> o cancela para abandonar el proceso.</p> */}
                                </div>
                                <div className="modal-footer">
                                    <Link to="/cancelar_flujo/pdf_video_token" className="btn btn-secondary" data-dismiss="modal">Cancelar</Link>
                                    <button type="button" onClick={this.obtenerToken} className="btn btn-primary btn-success">Reintentar</button>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                <div className="module_container overflow_hddn" style={{position: 'fixed'}}>
                    <div className="camera_capture_frame speech_mod">
                        <video src="" id="video_wrt_environment" playsInline muted></video>
                        {/*  <div data-vjs-player>
                            <video id="myVideo2" ref={node => this.videoNode = node} className={["video-js", "video-js", ((this.state.isComplete) ? "blur_video" : "")].join(" ")} playsInline></video>
                        </div> */}
                    </div>
                    <div className="module_gradident_overlay"></div>
                    <div className="module_title animated slideInDown">
                        {(this.state.isComplete) ?
                            (<p>Gracias por tu <b>paciencia</b></p>) : (<p>Ubica tu <b>rostro</b> dentro de la <b>guía</b></p>)}
                    </div>
                    <img className="id_mask animated fadeIn delay-2s" src={process.env.PUBLIC_URL + '/images/face_id_on.svg'} alt="" />
                    <div className="align_alert hide_elmnt"><p>Mantén tu rostro dentro del área definida para iniciar la lectura.</p>
                    </div>
                    <div className="recorder_container">
                        {(this.state.isComplete) ?
                            <div className="recorder_box">
                                <div className="circle_back" onClick={this.obtenerToken} >
                                    <div className="circle_front"></div>
                                </div>
                                <div className="audio_meter_box">
                                    <ul>
                                        <li className="b_a"></li>
                                        <li className="b_b"></li>
                                        <li className="b_c"></li>
                                        <li className="b_d"></li>
                                        <li className="b_e"></li>
                                        <li className="b_f"></li>
                                        <li className="b_g"></li>
                                        <li className="b_h"></li>
                                        <li className="b_i"></li>
                                        <li className="b_j"></li>
                                        <li className="b_k"></li>
                                        <li className="b_l"></li>
                                    </ul>
                                </div>
                            </div> : ("")}

                        {(this.state.isRecording) ? <div>
                            <div className="recorder_box">
                                <div className="circle_back recording_state" onClick={this.stopRecord}>
                                    <img className="hide_elmnt" src={process.env.PUBLIC_URL + '/images/warning_36.png'} width="35" alt="" />
                                    <div className="circle_front animated infinite pulse"></div>
                                </div>
                                <div className="audio_meter_box">
                                    <ul className="rec_state">
                                        <li className="b_a"></li>
                                        <li className="b_b anim_b"></li>
                                        <li className="b_c anim_c"></li>
                                        <li className="b_d anim_d"></li>
                                        <li className="b_e anim_e"></li>
                                        <li className="b_f anim_f"></li>
                                        <li className="b_g anim_g"></li>
                                        <li className="b_h anim_h"></li>
                                        <li className="b_i anim_i"></li>
                                        <li className="b_j anim_j"></li>
                                        <li className="b_k anim_k"></li>
                                        <li className="b_l"></li>
                                    </ul>
                                </div>
                            </div>
                        </div> : ("")}
                    </div>
                    {(this.state.isRecording) ? <div className="status_txt">
                        <p>escuchando</p>
                    </div> : ("")}
                </div>
                {(this.state.isStart) ? <Fragment> <div className="overlay_box">
                    <div className="alert_box">
                        <p className="animated slideInDown">Por favor <b>mantén tu rostro visible</b> dentro de la guía, <b>evita contraluz,</b> sin lentes, gorra, ni más personas en la toma y <b>lee el texto</b> en el orden que te será indicado.</p>
                        <button type="button" onClick={this.obtenerToken} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animated fadeIn delay-2s">SIGUIENTE</button>
                    </div>
                </div></Fragment> : ("")}
                {(this.state.conteo) ?
                    <div id="cuadro" className="red_flag_karaoke vsbl_bx animated">
                        <div id="head_shop" className="head_shop">
                            <p>Por favor <b>lee</b> el <b>texto</b> que <b>aparecerá</b> aquí en:</p>
                        </div>
                        <div className="center_nmbrs">
                            <div className="nmbr_bx">
                                <p id="numeros" className="">3</p>
                            </div>
                        </div>
                        <div className="red_flg"><img src="images/red_flag.svg" alt="" /></div>
                    </div> : ("")}
                {this.state.intents >= Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "3")) ? (<div className="err_finale  animated slideInUp">
                    <div className="center_checks">
                        <h5>Proceso no exitoso</h5>
                        <p>Haz superado el número <b>máximo</b> de intentos para este proceso inténtalo nuevamente <b>más tarde</b>.</p>
                        <p>Si deseas mayor información no dudes en contactarnos con el <b>ID</b> de proceso <b>{(this.state.uuid).split("-")[0]}</b> </p>
                        <br />
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <p><b>Llámanos al:</b></p>
                                    <p><a className="help_phone" href={"tel:" + obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a></p>
                                </div>
                                <div className="col">
                                    <p><b>Escríbenos a:</b></p>
                                    <p><a className="help_mail" href={"mailto:" + obtenerValorConfig(this.state.dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="action_buttons noscroll_screen">
                            <Link to={"/" + this.state.apikey} className="btn btn-raised btn-primary forcewidth100 main_bg_color">ENTENDIDO</Link>
                        </div>
                    </div>
                </div>) : ("")}

                {(this.state.isComplete && !this.state.hasResponse) ? <Loader /> : ("")}
                {
                    (isLandscape) ? (
                        <div className="rotate_device">
                            <div className="center_info_bx">
                                <img src="images/rotate_device.svg" height="100" alt="" />
                                <p>Por favor usa tu dispositivo en vertical<br />
                                    <small>Gira tu dispositivo para continuar</small>
                                </p>

                            </div>
                        </div>) : ("")}
            </div>
        );
    }
}
PDFVideoToken = withOrientationChange(PDFVideoToken);
export default withRouter(PDFVideoToken);