import React, { Fragment, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { configStyle, obtenerValorConfig } from '../services/configStyle'

const Header = (props) => {
    //process.env.PUBLIC_URL + '/images/wl/comp_logo.png'
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [hayCookies] = useState(navigator.cookieEnabled);
    const [pathname, setpathname] = useState('');

    useEffect(() => {
        let host = window.location.pathname;
        setpathname(host)

        if(hayCookies){
            let dataOtorganteLS = localStorage.getItem("dataOtorgante")
            if (dataOtorganteLS != null) {
                setDataOtorgante(JSON.parse(dataOtorganteLS))
                configStyle(JSON.parse(dataOtorganteLS))
            }
        }
        // //console.log(pathname);
        
    }, []);

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    return (
        <Fragment>
            <div id="head_container">
                <img id="imagenLogo" className="comp_logo animated slideInDown" src={(obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', "") != "") ? obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', process.env.PUBLIC_URL + '/images/wl/comp_logo.png') : process.env.PUBLIC_URL + '/images/wl/comp_logo.png'} alt="" />
                {
                    (pathname === "/Cw2qjkHFRvpLPryvUWXDcOPMEgHIYI9X" || pathname === "/7d59c982-2ada-4630-9f93-c5093d6bf6bb" || pathname === "/oNj4c0JK7nOit63c1PK0nF7asJSUejXa" || pathname === "/6E1BPtr71AQqmd07Vv8k12JdQIoD9F3q") ? 
                        <Link to={'/ayuda/' + props.ruta} className="help_btn animated fadeIn delay-1s"><img src={process.env.PUBLIC_URL + '/images/help_icon.png'} alt="" /></Link>
                        :
                        ""
                }
            </div>
        </Fragment>
    );
}

export default Header;