import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle, obtenerValorConfig } from '../services/configStyle';
import { evento } from '../services/data';

window.onbeforeunload = confirmExit;
function confirmExit() {
    return "show warning";
}

const Finalizado = () => {
    const [apiKey, setApiKey] = useState('')
    const [uuid, setUuid] = useState("")
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [hasPhone, setHasPhone] = useState(false)
    const history = useHistory();
    const [uuidTrx, setUuidTrx] = useState("");

    let tipo = '';
    let informacion = {};

    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
        }
        let uuidTrx = localStorage.getItem("uuidTrx")
        if (uuidTrx) {
            setUuidTrx(uuidTrx)
        }

    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    return (
        <div className="main_gradient">
            <Header ruta="finalizado" />
            <div className="main_text_container">
                <h1 className="animated">¡Muchas Gracias!</h1>
                <br />
                <br />
                <p className="animated">Tu proceso esta siendo procesado, si no cerró automáticamente la aplicación, presiona el botón de finalizar o en su defecto cierra esta ventana.</p>
            </div>
            <div className="main_icon_container">
                <br /><br />
                <p className="text-center" >Folio: <br />
                    <b>{uuidTrx.split("-")[0]}</b>
                </p>
                <br />
                    <div className="action_buttons fadeIn delay-1s">
                        <a className="btn btn-raised btn-primary forcewidth100 main_bg_color animated delay-3s border_button_20" onClick={ () => {
                                evento('Finalizado', 'Click', { description: 'FINALIZAR' }, true); 
                                setTimeout(() => {
                                    history.push('/' + apiKey);
                                }, 300);
                            }}>
                            Finalizar
                        </a>
                    </div>
                {/*!window.opener && <>
                    <br />
                    <div className="action_buttons fadeIn delay-1s">
                        <a className="btn btn-raised btn-primary forcewidth100 main_bg_color animated delay-3s border_button_20" onClick={() => history.push('/' + apiKey)}>
                            Finalizar
                        </a>
                    </div>
                </>*/}

            </div>
            <Footer />
        </div>
    )

}

export default Finalizado