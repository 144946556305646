import React from "react";
import Header from "../components/headerNA";

const ErrorPantalla = () => {
    
    
    return (
        
    <div className="container">
	
	<div className="main_gradient">
		
    <Header ruta="error_pantalla" />

					
		<div className="main_text_container">
			
			<h1 className="animated">Lo sentimos</h1>
			
			<p className="animated">
				La captura parece <b>no ser correcta</b>, por favor <b>intenta nuevamente</b> tomando en cuenta:
			</p>
			
			<ul className="">
			  <li>Coloca tu identificación sobre un fondo negro u obscuro</li>
			  <li>Asegúrate de enfocar correctamente y dentro del recuadro</li>
			  <li>Recuerda evitar reflejos de luz</li>
			</ul>
		
            </div>
		
		
		
		<div className="action_buttons noscroll_screen">
			
			<button type="button" className="btn btn-primary forcewidth100 main_color">Cancelar</button>
			<button type="button" className="btn btn-raised btn-primary forcewidth100 main_bg_color">Capturar nuevamente</button>
			
		</div>
		
		<div className="powered_by full_screen_spce"><img src="../images/powered_by.png" alt="" />
		</div>
		
	</div>
	
	<div className="loader_container hide_elmnt">
		<div className="ispinner ispinner-large">
					  <div className="ispinner-blade"></div>
					  <div className="ispinner-blade"></div>
					  <div className="ispinner-blade"></div>
					  <div className="ispinner-blade"></div>
					  <div className="ispinner-blade"></div>
					  <div className="ispinner-blade"></div>
					  <div className="ispinner-blade"></div>
					  <div className="ispinner-blade"></div>
					  <div className="ispinner-blade"></div>
					  <div className="ispinner-blade"></div>
					  <div className="ispinner-blade"></div>
					  <div className="ispinner-blade"></div>
		</div>
    </div>

    </div>
	
        
        )
  
     
}    


export default ErrorPantalla