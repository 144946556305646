import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import { actualizarEstado, enviarRenapo, enviarIne } from '../services/api.js';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { hayExcepcion, agregarInfo, status, statusData, esNumLetras, esSoloLetras, esNumeros, evento, statusError, statusReintento } from '../services/data'
import Info from '../components/encuentra_info';


let ResultadosIdentificacion = (props) => {
    const history = useHistory();
    const location = useLocation();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataOCRBack, setDataOCRBack] = useState({})
    const [dataOCRFront, setDataOCRFront] = useState({})
    const [nombre, setNombre] = useState('')
    const [focusNombre, setFocusNombre] = useState(false)
    const [nacimiento, setNacimiento] = useState('')
    const [focusNacimiento, setFocusNacimiento] = useState(false)
    const [curp, setCurp] = useState('')
    const [focusCurp, setFocusCurp] = useState(false)
    const [vigencia, setVigencia] = useState('')
    const [focusVigencia, setFocusVigencia] = useState(false)
    const [dataUser, setDataUser] = React.useState([])
    const [inputActive, setInputActive] = useState(false)
    const [esPassport, setEsPassport] = useState(false)
    const [esVigente, setEsVigente] = useState(true)
    const [datosCorrectos, setdatosCorrectos] = useState(true)
    const [noOCR, setNoOCR] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const [esPermanente, setEsPermanente] = useState(false)
    const [esMigratoria, setEsMigratoria] = useState(false);
    const [apellidoMaterno, setApellidoMaterno] = useState('');
    const [apellidoPaterno, setApellidoPaterno] = useState('');
    const [focusApellidoPaterno, setFocusApellidoPaterno] = useState(false);
    const [focusApellidoMaterno, setFocusApellidoMaterno] = useState(false);
    /*INE*/
    const [esIne, setEsIne] = useState(false)

    const [clave, setclave] = useState('')
    const [focusClave, setfocusClave] = useState(false)

    const [registro, setRegistro] = useState('')
    const [focusRegistro, setfocusRegistro] = useState(false)

    const [emision, setemision] = useState('')
    const [focusEmision, setfocusEmision] = useState(false)

    const [CIC, setCIC] = useState('')
    const [focusCIC, setfocusCIC] = useState(false)

    useEffect(() => {

        let no_OCR = localStorage.getItem("intentsData")
        if (no_OCR !== null) {
            if (no_OCR === "OCR")
                setNoOCR(true)
        }

        if (hayExcepcion()) {
            setNoOCR(true)
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        let dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (dataOCRFrontLS != null) {
            if (ocrFront.identificacionElectoral) {
                setEsIne(true);
                if (ocrFront.claveElector)
                    setclave((ocrFront.claveElector).trim())
                if (ocrFront.anioRegistro)
                    setRegistro((ocrFront.anioRegistro).trim())
                if (ocrFront.numeroRegistro)
                    setemision((ocrFront.numeroRegistro).trim())
            }
            if (ocrFront.identificacionPasaporte)
                setEsPassport(true)
            setDataOCRFront(ocrFront)
            if (ocrFront.nombres)
                setNombre(ocrFront.nombres);
            if (ocrFront.apellidoPaterno)
                setApellidoPaterno(ocrFront.apellidoPaterno);
            if (ocrFront.apellidoMaterno)
                setApellidoMaterno(ocrFront.apellidoMaterno);
            //setNombre(ocrFront.nombres + " " + ocrFront.apellidoPaterno + " " + ocrFront.apellidoMaterno)
            if (ocrFront.fechaNacimiento) {
                if (ocrFront.fechaNacimiento.length > 6 && ocrFront.fechaNacimiento.length < 10) {

                    let añoActualInt = new Date().getFullYear().toString().substr(-2);
                    let añoFechaOcr = ocrFront.fechaNacimiento.substr(-2);
                    let añoFechaOcrInt = ocrFront.fechaNacimiento.substr(-2);
                    let fechaSinAño = ocrFront.fechaNacimiento.substr(0, 6);
                    let fechaOcr = '';
                    if (añoFechaOcrInt >= añoActualInt) {
                        fechaOcr = fechaSinAño + '19' + añoFechaOcr;
                        formatoFechaOcr(fechaOcr);
                    } else {
                        let mayoriaDeEdad = añoActualInt - 18;
                        if (añoFechaOcrInt <= mayoriaDeEdad) {
                            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
                            formatoFechaOcr(fechaOcr);
                        } else {
                            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
                        }

                    }
                } else {
                    //console.log("entre fecha completa");
                    formatoFechaOcr(ocrFront.fechaNacimiento);
                }
            }
            if (hayExcepcion()) {
                if (!hayLetras(ocrFront.dni)) {
                    setCurp(ocrFront.dni)
                } else {
                    setCurp("")
                }
            } else {
                if (ocrFront.curp)
                    if (ocrFront.curp.length > 14)
                        setCurp((ocrFront.curp).trim())
            }
            let vigenciaC = ''
            if (ocrFront.vigencia != null) {
                if (ocrFront.identificacionPasaporte) {
                    if (ocrFront.identificacionPasaporte || hayExcepcion()) {
                        //vigenciaC = ocrFront.vigencia
                        if (ocrFront.vigencia.includes(' '))
                            vigenciaC = ocrFront.vigencia.replaceAll(' ', '/')
                        else
                            vigenciaC = ocrFront.vigencia
                    } else {
                        vigenciaC = "20" + ocrFront.vigencia.substr(-2)
                    }
                } else if (ocrFront.identificacionMigratoria) {
                    setEsMigratoria(true);
                    if (ocrFront.vigencia === "PERMANENTE") {
                        setEsPermanente(true);
                        vigenciaC = ocrFront.vigencia;
                    } else {
                        if (hayExcepcion()) {
                            //console.log("hayExcepcion");
                            if (ocrFront.vigencia.includes(' '))
                                vigenciaC = ocrFront.vigencia.replaceAll(' ', '/')
                            else
                                vigenciaC = ocrFront.vigencia
                        } else {
                            vigenciaC = "20" + ocrFront.vigencia.substr(-2)
                        }
                    }
                } else {
                    if (hayExcepcion()) {
                        //console.log("hayExcepcion");
                        if (ocrFront.vigencia.includes(' '))
                            vigenciaC = ocrFront.vigencia.replaceAll(' ', '/')
                        else
                            vigenciaC = ocrFront.vigencia
                    } else {
                        vigenciaC = "20" + ocrFront.vigencia.substr(-2)
                    }
                }
            }
            setVigencia(vigenciaC);
            //console.log("enfrente", vigenciaC);
        }

        let dataOCRBackLS = localStorage.getItem("ocrBack")

        if (dataOCRBackLS != null) {
            setDataOCRBack(JSON.parse(dataOCRBackLS))
            const ocrBack = JSON.parse(dataOCRBackLS)
            let vigenciaC = '';
            //console.log("datos:", dataOCRBack, dataOCRFront, inputActive);
            if (ocrBack.ineVigencia != null) {
                vigenciaC = ocrBack.ineVigencia;
                let formatosP = ['DD/MM/YYYY', 'DD/MM/YY'];
                if (moment(vigenciaC, formatosP, true).isValid()) {
                    setVigencia("20" + vigenciaC.substr(-2))
                }
            } else {
                if (ocrFront.vigencia != null) {
                    //console.log("v: " + vigenciaC)
                    if (vigenciaC) {
                        setVigencia("20" + vigenciaC.substr(-2))
                        //console.log("fecha:" + vigenciaC);
                    } else {
                        let vi = ""
                        if (ocrFront.vigencia.includes(' '))
                            vi = ocrFront.vigencia.replaceAll(' ', '/')
                        else
                            vi = ocrFront.vigencia
                        setVigencia(vi)
                    }
                } else {
                    if (hayExcepcion()) {
                        vigenciaC = ""
                    } else {
                        if (ocrFront.vigencia) {
                            vigenciaC = "20" + ocrFront.vigencia.substr(-2)
                        }
                    }
                    setVigencia(vigenciaC)
                }
            }
            if (ocrBack.cicMRZ)
                setCIC((ocrBack.cicMRZ).trim())
        }
        status("datos_personales_ocr_page", "Datos personales OCR")
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser])

    const hayLetras = (input) => {
        let result = true
        var expreg = /[a-z]/gi;
        //var result = input.match(expreg);
        if (!input.match(expreg))
            result = false
        return result
    }

    const actualizarDatos = (e) => {
        e.preventDefault();
        sendEventClick('SIGUIENTE', {});
        let continuar = true;
        let frase = "Revisa el formato de fecha dd/mm/aaaa"
        let formatosP = ['DD/MM/YYYY']
        let tipo = '';

        if (noOCR) {
            if (nombre.length < 3 || !esSoloLetras(nombre)) {
                continuar = false; frase = "Nombre no válido"
                document.getElementById("ht-nombre").innerHTML = frase
            } else {
                document.getElementById("ht-nombre").innerHTML = ""
            }
            if (apellidoPaterno.length < 3 || !esSoloLetras(apellidoPaterno)) {
                continuar = false; frase = "Apellido paterno no válido"
                document.getElementById("ht-apellidoPaterno").innerHTML = frase
            } else {
                document.getElementById("ht-apellidoPaterno").innerHTML = ""
            }
            if (!esSoloLetras(apellidoMaterno)) {
                continuar = false; frase = "Apellido materno no válido"
                document.getElementById("ht-apellidoMaterno").innerHTML = frase
            } else {
                document.getElementById("ht-apellidoMaterno").innerHTML = ""
            }
            let nInput = document.getElementById("inputFechaNacimiento").value;
            setNacimiento(nInput)
            if (moment(nInput, formatosP, true).isValid()) {
                document.getElementById("ht-nacimiento").innerHTML = ""
            } else {
                continuar = false; frase = "Revisa el formato dd/mm/aaaa"
                document.getElementById("ht-nacimiento").innerHTML = frase
            }
            if (esPassport || hayExcepcion()) {
                let vigenciaInput = document.getElementById("inputFechaVigencia").value;
                let fechaActual = vigenciaInput;
                let dateParts = fechaActual.split("/");
                let fechaV = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                if (fechaV >= Date.now()) {
                    setVigencia(vigenciaInput)
                    if (moment(vigenciaInput, formatosP, true).isValid()) {
                        document.getElementById("ht-vigencia").innerHTML = ""
                    } else {
                        continuar = false; frase = "Revisa el formato dd/mm/aaaa"
                        document.getElementById("ht-vigencia").innerHTML = frase
                    }
                } else {
                    continuar = false;
                    setEsVigente(false);
                }

            } else {
                let today = new Date();
                let year = today.getFullYear();
                if (Number(vigencia) >= year) {
                    if ((vigencia.length != 4) && (esPermanente == false)) {
                        continuar = false; frase = "Revisa que el año sea 4 digitos"
                        document.getElementById("ht-vigencia").innerHTML = frase
                    } else if (!esNumeros(vigencia) && (esPermanente == false)) {
                        continuar = false; frase = "Formato de vigencia no válido"
                        document.getElementById("ht-vigencia").innerHTML = frase
                    } else {
                        setVigencia(vigencia); document.getElementById("ht-vigencia").innerHTML = ""
                    }
                } else {
                    continuar = false;
                    setEsVigente(false);
                }
            }
            if (!hayExcepcion()) {
                if (curp.length < 16 || !esNumLetras(curp) || curp.length > 18) {
                    continuar = false; frase = "Formato de Curp no válido"
                    document.getElementById("ht-curp").innerHTML = frase
                } else {
                    document.getElementById("ht-curp").innerHTML = ""
                }
            } else {
                if (curp.length < 3 || !esNumLetras(curp)) {
                    continuar = false; frase = "Formato de DNI no válido"
                    document.getElementById("ht-curp").innerHTML = frase
                } else {
                    document.getElementById("ht-curp").innerHTML = ""
                }
            }

        } else {
            let nInput = document.getElementById("inputFechaNacimiento").value;
            setNacimiento(nInput)
            if (moment(nInput, formatosP, true).isValid()) {
                document.getElementById("ht-nacimiento").innerHTML = ""
            } else {
                continuar = false; frase = "Revisa el formato dd/mm/aaaa"
                document.getElementById("ht-nacimiento").innerHTML = frase
            }
            if (esPassport || hayExcepcion()) {
                let vigenciaInput = document.getElementById("inputFechaVigencia").value;
                let fechaActual = vigenciaInput;
                let dateParts = fechaActual.split("/");
                let fechaV = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                if (fechaV >= Date.now()) {
                    setVigencia(vigenciaInput)
                    if (moment(vigenciaInput, formatosP, true).isValid()) {
                        document.getElementById("ht-vigencia").innerHTML = ""
                    } else {
                        continuar = false; frase = "Revisa el formato dd/mm/aaaa"
                        document.getElementById("ht-vigencia").innerHTML = frase
                    }
                } else {
                    continuar = false;
                    setEsVigente(false);
                }

            } else {
                let today = new Date();
                let year = today.getFullYear();
                if (Number(vigencia) >= year) {
                    if ((vigencia.length != 4) && (esPermanente == false)) {
                        continuar = false; frase = "Revisa que el año sea 4 digitos"
                        document.getElementById("ht-vigencia").innerHTML = frase
                    } else {
                        setVigencia(vigencia); document.getElementById("ht-vigencia").innerHTML = ""
                    }
                } else {
                    continuar = false;
                    setEsVigente(false);
                }

            }
            //continuar = true;
        }

        if (!esNumLetras(curp) || curp.length < 16 || curp.length > 18) {
            continuar = false; frase = "Formato de Curp no válido"
            document.getElementById("ht-curp").innerHTML = frase
        } else {
            document.getElementById("ht-curp").innerHTML = ""
        }

        //console.log("vigencia",vigencia);

        if (continuar) {
            // if (obtenerValorConfig(dataOtorgante, 'ENABLE_RENAPO', "true") === "true") {
            //     enviarRenapo(curp).then(response => {
            //         //console.log("RENAPO");
            //     }).catch(error => {
            //         //console.log("RENAPO: " + error);
            //     });
            // }

            // if (obtenerValorConfig(dataOtorgante, 'ENABLE_INE', "true") === "true" && esIne) {
            //     enviarIne(CIC, clave, emision).then(response => {
            //         //console.log("INE");
            //     }).catch(error => {
            //         //console.log("INE: " + error);
            //     });
            // }


            let infoFormulario = []
            agregarInfo(dataUser, { description: "nombre", value: nombre });
            infoFormulario.push({ description: "nombre", value: nombre })
            agregarInfo(dataUser, { description: "apellidoPaterno", value: apellidoPaterno });
            infoFormulario.push({ description: "apellidoPaterno", value: apellidoPaterno })
            agregarInfo(dataUser, { description: "apellidoMaterno", value: apellidoMaterno });
            infoFormulario.push({ description: "apellidoMaterno", value: apellidoMaterno });
            let nInput = document.getElementById("inputFechaNacimiento").value;
            agregarInfo(dataUser, { description: "nacimiento", value: nInput });
            infoFormulario.push({ description: "nacimiento", value: nInput })

            if (hayExcepcion()) {
                agregarInfo(dataUser, { description: "dni", value: curp });
                infoFormulario.push({ description: "dni", value: curp })
            } else {
                agregarInfo(dataUser, { description: "curp", value: curp });
                infoFormulario.push({ description: "curp", value: curp })
            }
            if (esPassport || hayExcepcion()) {
                let vinput = document.getElementById("inputFechaVigencia").value
                infoFormulario.push({ description: "vigencia", value: vinput })
                agregarInfo(dataUser, { description: "vigencia", value: vinput });
            } else {
                infoFormulario.push({ description: "vigencia", value: "31/12/20" + vigencia.substr(-2) })
                agregarInfo(dataUser, { description: "vigencia", value: "31/12/20" + vigencia.substr(-2) });
            }

            sendData(infoFormulario);
            setTimeout(() => {
                history.push('/identificacion_edit');
            }, 300);
        }
    }

    useEffect(() => {
        if (noOCR) {
            setdatosCorrectos(true)
        } else {
            if (curp && nombre) {
                if (hayExcepcion()) {
                    if (curp.length < 3 || nombre.length < 3  || apellidoPaterno.length < 3) {
                        setdatosCorrectos(false)
                    } else {
                        setdatosCorrectos(true)
                    }
                } else {
                    if (curp.length < 16 || curp.length > 18 || nombre.length < 3  || apellidoPaterno.length < 3) {
                        setdatosCorrectos(false)
                    } else {
                        setdatosCorrectos(true)
                    }
                }
            } else {
                setdatosCorrectos(false)
            }
        }
        //console.log("DC:",datosCorrectos);
    }, [curp, nombre, apellidoPaterno])

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
        //var res = JSON.stringify(jsonObj)
    }

    const formatoFechaOcr = (fecha) => {
        let formatosP = ['DD/MM/YYYY', 'DD/MM/YY']
        let nacimientoC = fecha
        if (fecha.includes(' '))
            nacimientoC = fecha.replaceAll(' ', '/')
        if (moment(nacimientoC, formatosP, true).isValid())
            setNacimiento(nacimientoC)
        else {
            setNacimiento("")
        }
    }

    const sendEventClick = (description, objectInformation) => {
        let objeto = { description: description };
        let objetoEvento = {
            ...objeto,
            ...objectInformation
        }
        evento('Datos personales OCR', 'Click', objetoEvento, true);
    }

    const statusE = () => {
        let data = "Cancelado";
        statusError("Datos personales OCR", data, "cancelado");

    }

    const statusSE = () => {
        let data = "Reintento de captura de identificación";
        statusReintento("Datos personales OCR", data, "RETRY");
        // if (window.opener) {
        //     setTimeout(() => {
        //         history.push('/' + apiKey);
        //     }, 300);
        // }
    }

    return (
        <div className="main_gradient">
            <Header ruta="resultados_identificacion" />
            <div className="main_text_container">
                <h1 className="animated">Confirma tus datos</h1>
                <p className="animated">Verifica que esta información sea correcta, de lo contrario corrígela.</p>
            </div>
            <form>
                <div className="ocr_data_display animated">
                    <div className={["form-group", "bmd-form-group", ((focusNombre || nombre !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="inputNombreCompleto" className="bmd-label-floating label_atd">Nombres(s)</label>
                        <input type="text" className="form-control input_atd" id="inputNombreCompleto" defaultValue={nombre} onChange={e => setNombre(e.target.value)} onFocus={e => {
                            setFocusNombre(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (nombre === "") {
                                setFocusNombre(false)
                            }
                            setInputActive(false)
                        }} />
                        <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right"></span>
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusApellidoPaterno || apellidoPaterno !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="inputApellidoPaterno" className="bmd-label-floating label_atd">Apellido paterno</label>
                        <input type="text" className="form-control input_atd" id="inputApellidoPaterno" defaultValue={apellidoPaterno} onChange={e => setApellidoPaterno(e.target.value)} onFocus={e => {
                            setFocusApellidoPaterno(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (apellidoPaterno === "") {
                                setApellidoPaterno(false)
                            }
                            setInputActive(false)
                        }} />
                        <span id="ht-apellidoPaterno" className="helper-text ht" data-error="wrong" data-success="right"></span>
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusApellidoMaterno || apellidoMaterno !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="inputApellidoMaterno" className="bmd-label-floating label_atd">Apellido materno</label>
                        <input type="text" className="form-control input_atd" id="inputApellidoMaterno" defaultValue={apellidoMaterno} onChange={e => setApellidoMaterno(e.target.value)} onFocus={e => {
                            setFocusApellidoMaterno(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (apellidoPaterno === "") {
                                setApellidoMaterno(false)
                            }
                            setInputActive(false)
                        }} />
                        <span id="ht-apellidoMaterno" className="helper-text ht" data-error="wrong" data-success="right"></span>
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusNacimiento || nacimiento !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="inputFechaNacimiento" className="bmd-label-floating label_atd">Fecha de nacimiento</label>
                        <NumberFormat format="##/##/####" className="form-control input_atd" id="inputFechaNacimiento" value={nacimiento} onChange={e => setNacimiento(e.target.value)} onFocus={e => {
                            setFocusNacimiento(true)
                            setInputActive(true)
                        }
                        } onBlur={e => {
                            if (nacimiento === "") {
                                setFocusNacimiento(false)
                            }
                            setInputActive(false)
                        }} />
                        <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right"></span>
                    </div>
                    {(true) ?
                        <div>
                            <div className={["form-group", "bmd-form-group", ((focusVigencia || vigencia !== "") ? "is-focused" : "")].join(" ")}>
                                <label htmlFor="inputFechaVigencia" className="bmd-label-floating label_atd">{((esPassport || hayExcepcion()) ? "Vencimiento identificación" : "Vencimiento identificación (año)")}</label>
                                {
                                    (esPassport || hayExcepcion()) ?
                                        <NumberFormat format="##/##/####" className="form-control input_atd" id="inputFechaVigencia" value={vigencia} onChange={e => setVigencia(e.target.value)} onFocus={e => {
                                            setFocusVigencia(true)
                                            setInputActive(true)
                                        }} onBlur={e => {
                                            if (vigencia === "") {
                                                setFocusVigencia(false)
                                            }
                                            setInputActive(false)
                                        }} />
                                        : (esPermanente) ?
                                            <input type="text" className="form-control input_atd" id="inputFechaVigencia" defaultValue={vigencia} onChange={e => setVigencia(e.target.value)} onFocus={e => {
                                                setFocusVigencia(true)
                                                setInputActive(true)
                                            }} onBlur={e => {
                                                if (vigencia === "") {
                                                    setFocusVigencia(false)
                                                }
                                                setInputActive(false)
                                            }} />
                                            :
                                            <input type="text" className="form-control input_atd" id="inputFechaVigencia" maxLength="4" defaultValue={vigencia} onChange={e => setVigencia(e.target.value)} onFocus={e => {
                                                setFocusVigencia(true)
                                                setInputActive(true)
                                            }} onBlur={e => {
                                                if (vigencia === "") {
                                                    setFocusVigencia(false)
                                                }
                                                setInputActive(false)
                                            }} />
                                }

                                <span id="ht-vigencia" className="helper-text ht" data-error="wrong" data-success="right"></span>
                            </div>
                            <div className={["form-group", "bmd-form-group", ((focusCurp || curp !== "") ? "is-focused" : "")].join(" ")}>
                                <label htmlFor="inputCurp" className="bmd-label-floating label_atd">{(hayExcepcion()) ? "DNI" : "CURP"}</label>
                                {
                                    (esMigratoria) ?
                                        <input type="text" className="form-control input_atd" id="inputCurp" defaultValue={curp} onChange={e => setCurp(e.target.value)} onFocus={e => {
                                            setFocusCurp(true)
                                            setInputActive(true)
                                        }} onBlur={e => {
                                            if (curp === "") {
                                                setFocusCurp(false)
                                            }
                                            setInputActive(false)
                                        }} disabled={curp ? true : false} maxLength="19" />
                                        :
                                        <input type="text" className="form-control input_atd" id="inputCurp" defaultValue={curp} onChange={e => setCurp(e.target.value)} onFocus={e => {
                                            setFocusCurp(true)
                                            setInputActive(true)
                                        }} onBlur={e => {
                                            if (curp === "") {
                                                setFocusCurp(false)
                                            }
                                            setInputActive(false)
                                        }} />
                                }

                                <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right"></span>
                            </div>
                        </div>
                        :
                        <div>
                            <div className={["form-group", "bmd-form-group", ((focusClave || clave !== "") ? "is-focused" : "")].join(" ")}>
                                <label htmlFor="inputclaveCompleto" className="bmd-label-floating label_atd">Clave de elector:<small>(Dato frontal)</small></label>
                                <input type="text" className="form-control input_atd" id="inputclaveCompleto" maxLength="20" defaultValue={clave} onChange={e => setclave(e.target.value)} onFocus={e => {
                                    setfocusClave(true)
                                }} onBlur={e => {
                                    if (clave === "") {
                                        setfocusClave(false)
                                    }
                                }} />
                                <span id="ht-clave" className="helper-text ht" data-error="wrong" data-success="right"></span>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className={["form-group", "bmd-form-group", ((focusRegistro || registro !== "") ? "is-focused" : "")].join(" ")}>
                                        <label htmlFor="inputFechaRegistro" className="bmd-label-floating label_atd">Año de registro:<small>(Dato frontal)</small></label>
                                        <input type="text" className="form-control input_atd" id="inputFechaRegistro" maxLength="4" value={registro} onChange={e => setRegistro(e.target.value)} onFocus={e => {
                                            setfocusRegistro(true)
                                        }
                                        } onBlur={e => {
                                            if (registro === "") {
                                                setfocusRegistro(false)
                                            }
                                        }} />
                                        <span id="ht-registro" className="helper-text ht" data-error="wrong" data-success="right"></span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className={["form-group", "bmd-form-group", ((focusEmision || emision !== "") ? "is-focused" : "")].join(" ")}>
                                        <label htmlFor="inputFechaemision" className="bmd-label-floating label_atd">Número de emisión:<small>(Dato frontal)</small></label>
                                        <input type="text" className="form-control input_atd" id="inputFechaemision" value={emision} maxLength="2" onChange={e => setemision(e.target.value)} onFocus={e => {
                                            setfocusEmision(true)
                                        }
                                        } onBlur={e => {
                                            if (emision === "") {
                                                setfocusEmision(false)
                                            }
                                        }} />
                                        <span id="ht-emision" className="helper-text ht" data-error="wrong" data-success="right"></span>
                                    </div>
                                </div>
                            </div>

                            <div className={["form-group", "bmd-form-group", ((focusCIC || CIC !== "") ? "is-focused" : "")].join(" ")}>
                                <label htmlFor="inputCIC" className="bmd-label-floating label_atd">CIC/OCR:<small>(Dato reverso)</small></label>
                                <input type="text" className="form-control input_atd" id="inputCIC" maxLength="13" defaultValue={CIC} onChange={e => setCIC(e.target.value)} onFocus={e => {
                                    setfocusCIC(true)
                                }} onBlur={e => {
                                    if (CIC === "") {
                                        setfocusCIC(false)
                                    }
                                }} />
                                <span id="ht-CIC" className="helper-text ht" data-error="wrong" data-success="right"></span>
                            </div>

                            <div className={["form-group", "bmd-form-group", ((focusCurp || curp !== "") ? "is-focused" : "")].join(" ")}>
                                <label htmlFor="inputCurp" className="bmd-label-floating label_atd">{(hayExcepcion()) ? "DNI" : "CURP"}</label>
                                <input type="text" className="form-control input_atd uppercase" id="inputCurp" defaultValue={curp} maxLength="19" onChange={e => setCurp(e.target.value.toLocaleUpperCase())} onFocus={e => {
                                    setFocusCurp(true)
                                }} onBlur={e => {
                                    if (curp === "") {
                                        setFocusCurp(false)
                                    }
                                }} />
                                <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right"></span>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <div className={["form-group", "bmd-form-group", ((focusVigencia || vigencia !== "") ? "is-focused" : "")].join(" ")}>
                                        <label htmlFor="inputFechaVigencia" className="bmd-label-floating label_atd">{((esPassport || hayExcepcion()) ? "Vencimiento identificación" : "Vencimiento identificación:")}</label>
                                        {
                                            (esPassport || hayExcepcion()) ?
                                                (vigencia !== "PERMANENTE") ?
                                                    <NumberFormat format="##/##/####" className="form-control input_atd" id="inputFechaVigencia" value={vigencia} onChange={e => setVigencia(e.target.value)} onFocus={e => {
                                                        setFocusVigencia(true)
                                                    }} onBlur={e => {
                                                        if (vigencia === "") {
                                                            setFocusVigencia(false)
                                                        }
                                                    }} />
                                                    :
                                                    <input type="text" className="form-control input_atd" id="inputFechaVigencia" defaultValue={vigencia} onChange={e => setVigencia(e.target.value)} onFocus={e => {
                                                        setFocusVigencia(true)
                                                    }} onBlur={e => {
                                                        if (vigencia === "") {
                                                            setFocusVigencia(false)
                                                        }
                                                    }} />
                                                :
                                                <input type="text" className="form-control input_atd" id="inputFechaVigencia" maxLength="4" defaultValue={vigencia} onChange={e => setVigencia(e.target.value)} onFocus={e => {
                                                    setFocusVigencia(true)
                                                }} onBlur={e => {
                                                    if (vigencia === "") {
                                                        setFocusVigencia(false)
                                                    }
                                                }} />
                                        }

                                        <span id="ht-vigencia" className="helper-text ht" data-error="wrong" data-success="right"></span>
                                    </div>
                                </div>
                                <div className="col">

                                </div>
                            </div>
                            <Info />
                        </div>
                    }
                </div>
                <div className="action_buttons animated">
                    <button type="button" disabled={(esMigratoria) ? (!nombre || !nacimiento || !vigencia || !curp) : (!nombre || !nacimiento || !vigencia || (curp.length < 16))} className={["btn btn-raised btn-primary forcewidth100 main_bg_color border_button_20", ((!datosCorrectos) ? "disabled" : "")].join(" ")} onClick={e => actualizarDatos(e)}>SIGUIENTE</button>
                    <Link to={{
                        pathname: "/" + apiKey,
                        state: { passport: esPassport }
                    }} onClick={e => { sendEventClick('CAPTURAR OTRA VEZ', {}); statusSE(); localStorage.setItem( 'reintentos', true )}} className="btn btn-primary forcewidth100 main_color">CAPTURAR OTRA VEZ</Link>
                </div>
            </form>
            {(!esVigente) && (<div className="err_finale  animated slideInUp">
                <div className="center_checks">
                    <h5>Identificación no valida </h5>
                    <p>La <b>vigencia</b> de tu identificación <b>{vigencia}</b> no es valida, por favor <b>renueva tu identificación</b> e intenta otro día, si crees que es un error <b>intenta capturarla nuevamente</b>.
                    </p>
                    <br />
                    <div className="action_buttons noscroll_screen">
                        <Link to={{
                            pathname: "/" + apiKey,
                            state: { passport: esPassport }
                        }} className="btn btn-raised btn-primary forcewidth100 main_bg_color border_button_20" onClick={() => { sendEventClick('CAPTURAR OTRA VEZ', {}); statusSE(); localStorage.setItem( 'reintentos', true )}}>CAPTURAR OTRA VEZ</Link>
                        <Link to={{
                            pathname: "/" + apiKey
                        }} className="btn btn-secondary forcewidth100" onClick={() => { sendEventClick('TERMINAR', {}); statusE() }}>TERMINAR</Link>
                    </div>
                </div>
            </div>)}
            <Footer />
        </div>
    )
}

export default ResultadosIdentificacion